import React, { useEffect, useState } from "react";
import "../../../index.scss";
import "./ProductListPage.scss";
import { FormattedMessage } from "react-intl";

import { getProducts } from "../../../services/utilsProduct/utilsProduct";
import SearchBar from "../../../components/searchBar/searchBar";
import ProductCard from "../../../components/supervisor/ProductCard/ProductCard";
import ChipsArray from "../../../components/supervisor/ChipsArray/ChipsArray";
import IngredientListPage from "../../Ingredient/IngredientListPage/IngredientListPage";
import ProductCategoryListPage from "../../ProductCategory/ProductCategoryListPage/ProductCategoryListPage";
import {Fab, LinearProgress} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

const ProductListPage = () => {
  //Productos
  const [inputProduct, setInputProduct] = useState("");
  const [productListDefault, setProductListDefault] = useState([]);
  const [productList, setProductList] = useState([]);

  //Categorias - product menu
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesOn, setCategoriesOn] = useState([]);

  //All Products
  const [allProduts, setAllProducts] = useState([]);

  //Set loading
  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);


  const fetchData = async () => {
    setTableLoading(true);
    getProducts().then((data) => {
      console.log(data,"Products")
      let bigCategories = data["product_categories"];
      let categories = bigCategories.map((cat, index) => ({
        key: index,
        label: cat.name,
        active: false,
      }));

      let productArray = [];
      for (let i = 0; i < bigCategories.length; i++) {
        const element = bigCategories[i]["products"];
        for (let j = 0; j < element.length; j++) {
          const element2 = element[j];
          productArray.push(element2);
        }
      }
      setProductListDefault(productArray);
      setProductList(productArray);
      setCategoriesList(categories);

      setAllProducts(bigCategories);
      setLoading(false);
      setTableLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateInputProducts = async (input) => {
    const filtered = productListDefault.filter((product) => {
      if(product.barcode){
        return product.name.toLowerCase().includes(input.toLowerCase()) || product.barcode.toLowerCase().includes(input.toLowerCase());
      }
      else{
        return product.name.toLowerCase().includes(input.toLowerCase());
      }

    });
    setInputProduct(input);
    setProductList(filtered);
  };

  const updateChipsProducts = async (dataChip) => {
    /**Para hacer que funcione
     * Almacenar para saber que categorias estan
     * Recorrer los productos para solo coger las
     */
    let categorias = categoriesOn;

    /**Entonces, la cosa aca
     * 1. Si esta vacio => se crea un nuevo arreglo con solo 1
     * 2. Si ya esta lleno pues nada se agrega otra
     * 3. Si solo queda 1 y es el que esta llegando pues perro se va a all products
     */


    if (categorias.includes(dataChip)) {
      for (let i = 0; i < categorias.length; i++) {
        const keyElement = categorias[i].key;
        if(keyElement === dataChip.key){
          categorias.splice(i, 1);
        }
        
      }
      
    } else {
      categorias.push(dataChip);
    }

    setCategoriesOn(categorias);
    searchProductsOfCategories(categorias);
  };

  function searchProductsOfCategories(categories) {
    let indexCat = categories.map((item) => item.key);
    let fullProductsByFilter = [];
    for (let i = 0; i < indexCat.length; i++) {
      const index = indexCat[i];
      let productsInCat = allProduts[index]["products"];
      for (let j = 0; j < productsInCat.length; j++) {
        const product = productsInCat[j];
        fullProductsByFilter.push(product);
      }
    }
    if (fullProductsByFilter.length === 0) {
      fullProductsByFilter = productListDefault;
    }
    
    setProductList(fullProductsByFilter);
  }

  // ----------------------------------------------------
  // ------------------ Ingredients ---------------------
  // ----------------------------------------------------

  return (
    <article className="row">


      {productListDefault && (
        <SearchBar
          input={inputProduct}
          onChange={updateInputProducts}
          nombre="Product"
        />
      )}
      {tableLoading && <LinearProgress />}
      { !tableLoading && categoriesList && (
        <ChipsArray
          chipsData={categoriesList}
          chipManage={updateChipsProducts}
        ></ChipsArray>
      )}
      <div className="products-container">
        {productList &&
          productList.map((product, i) => (
            <ProductCard product={product} key={i}></ProductCard>
          ))}
      </div>
    </article>
  );
};

export default ProductListPage;
