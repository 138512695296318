import {
  URL_DOMAIN,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsDelete,
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[7];

const supervisorId = localStorage.getItem("fk_supervisor");

const getInvoiceSchemas = async () => {
  return fetch(
    URL_FINAL + "/get-all-invoice-schema?fk_supervisor=" + supervisorId,
    requestOptionsGet
  ).then(getJSON);
};

const getInvoiceSchemaById = async (id) => {
  return fetch(
    URL_FINAL + "/get-invoice-schema?id=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const postInvoiceSchema = async (formData) => {
  requestOptionsPost.body = formData;
  console.log(formData.values);
  return fetch(URL_FINAL + "/create-invoice-schema", requestOptionsPost).then(
    getJSON
  );
};

const putInvoiceSchema = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_FINAL + "/edit-invoice-schema", requestOptionsPut).then(
    getJSON
  );
};

const getFranchisesByInvoiceSchema = async (id) => {
  return fetch(
    URL_FINAL + "/get-franchise-by-invoice-schema?fk_invoice_schema=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const removeFranchiseToInvoiceSchema = async (formData) => {
  requestOptionsDelete.body = formData;
  return fetch(URL_FINAL + "/remove-franchise", requestOptionsDelete).then(
    getJSON
  );
};

const addFranchiseToInvoiceSchema = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_FINAL + "/add-franchise", requestOptionsPost).then(
    getJSON
  );
};

export {
  getInvoiceSchemaById,
  getInvoiceSchemas,
  postInvoiceSchema,
  putInvoiceSchema,
  getFranchisesByInvoiceSchema,
  removeFranchiseToInvoiceSchema,
  addFranchiseToInvoiceSchema
};
