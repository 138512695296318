import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/Table/Table";
import EditPrimaryIcon from "../../../assets/icons/edit-24px.svg";
import { LinearProgress } from "@material-ui/core";
import { getInvoiceSchemas } from "../../../services/utilsInvoiceSchema/utilsInvoiceSchema";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";

const InvoiceSchemaListPage = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  const [invoiceSchemas, setInvoiceSchemas] = useState([]);

  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);

  const invoiceSchemaTableHeaders = [
    "numberHeader",
    "startValueHeader",
    "minValueHeader",
    "maxValueHeader",
    "approvedDateHeader",
    "actionsHeader",
  ];

  const createInvoiceSchemaData = (
    number,
    startValue,
    minValue,
    maxValue,
    approvedDate,
    id
  ) => {
    return {
      number,
      startValue,
      minValue,
      maxValue,
      approvedDate,
      actions: (
        <Link
          to={`/resoluciones-facturacion/${number}/edit`}
          onClick={() => goInvoiceSchema(id)}
        >
          <img
            src={EditPrimaryIcon}
            alt="edit"
            onClick={() => console.log("id")}
          />
        </Link>
      ),
    };
  };

  const goInvoiceSchema = (id) => {
    localStorage.setItem("fk_invoice_schema", id);
  };

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async () => {
    setTableLoading(true);
    getInvoiceSchemas().then((data) => {
      console.log(data);

      let invoiceSchemaData = data.invoice_schemas.map((invoiceSchema) =>
        createInvoiceSchemaData(
          invoiceSchema.number,
          invoiceSchema.start_value,
          invoiceSchema.min_value,
          invoiceSchema.max_value,
          invoiceSchema.date_approved,
          invoiceSchema.id
        )
      );
      setInvoiceSchemas(invoiceSchemaData);
      setLoading(false);
      setTableLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!loading && (
        <>
          {tableLoading && <LinearProgress />}
          <CustomTable
            headers={invoiceSchemaTableHeaders}
            rows={invoiceSchemas}
            pagination={""}
            handleChangePage={""}
            handleChangeRowsPerPage={""}
          />
        </>
      )}
    </>
  );
};

export default InvoiceSchemaListPage;
