import {
  URL_DOMAIN,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[1];

const supervisorId = localStorage.getItem("fk_supervisor");
/**
 * Franchises
 * @returns
 */

const getOperators = async () => {
  return fetch(
    URL_FINAL +
      "/get-operators-of-supervisor?fk_supervisor=" +
      supervisorId,
    requestOptionsGet
  ).then(getJSON);
};

const getOperatorById = async (id) => {
  return fetch(
    URL_FINAL + "/get-operator?fk_operator=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const postOperator = async (formData) => {
  requestOptionsPost.body = formData;
  console.log(formData.values)
  return fetch(URL_FINAL + "/create-operator", requestOptionsPost).then(
    getJSON
  );
};

const putOperator = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_FINAL + "/edit-operator", requestOptionsPut).then(
    getJSON
  );
};

export { getOperators, getOperatorById, postOperator, putOperator};
