import * as d3 from "d3";

export function PieChartHome(fill_tables, total_tables) {
  console.log("DATAAA", fill_tables, total_tables);

  // Data to be added in the pie chart

  var data = [
    { fill_tables: fill_tables, key: "fill_tables" },
    { total_tables: total_tables, key: "total_tables" },
  ];

  //var data =[ fill_tables: 12, total_tables]
  let width = 100;
  let height = 100;
  let margin = 0;

  // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
  var radius = Math.min(width, height) / 2 - margin;

  // Selecting SVG using d3.select()
  var svg = d3.select("svg");

  var color = d3
    .scaleOrdinal()
    .domain(["fill_tables", "total_tables"])
    .range(["#F6B716", "#FCE19C"]);

  // Creating Pie generator

  var pie = d3
    .pie()
    // Use of pie.value() Function
    .value((d) => {
      console.log(d);
      if (d.total_tables) {
        return d.total_tables;
      } else {
        return d.fill_tables;
      }
    })(data);

  // Creating arc
  var arc = d3
    .arc()
    .innerRadius(radius / 1.5)
    .outerRadius(radius);

  let g = svg
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  // Grouping different arcs
  var arcs = g.selectAll("arc").data(pie).enter().append("g");

  // Appending path
  arcs
    .append("path")
    .attr("fill", (data, i) => {
      return d3.schemeSet2[i];
    })
    .attr("fill", function (d) {
      return color(d.data.key);
    })
    .attr("d", arc);

  arcs
    .append("text")
    .attr("transform", (d) => {
      return "translate(" + arc.centroid(d) + ")";
    })
    .text(function (d) {
      return d.value;
    });
}
