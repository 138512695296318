import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../Marker/Marker'


class SimpleMap extends Component {
  // static defaultProps = {
  //   center: {
  //     lat: 4.60971,
  //     lng: -74.08175
  //   },
  //   zoom: 11
  // };

  state = {
      marker: (<></>),
    center: {
      lat: 4.60971,
      lng: -74.08175
    },
    loading: true
  }

  changeLocation = (position)=>{
    console.log("position", position)
    let marker = (<></>)
    if(this.props.center !== undefined){
      if(this.props.center.lat!==undefined && this.props.center.lat!==undefined){
        console.log("no entro")
        marker=(<Marker
          lat={position.coords.latitude}
          lng={position.coords.longitude}
          name="location"
          color="#78065D"
        />)
      }
      
    }
    this.setState({
      center:{
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    },
      marker:marker,
      loading: false

    })

    console.log("changed location")
  }

  setInitialCenter = ()=> {
    console.log(this.props)
    if(this.props.center===undefined){
      navigator.geolocation.getCurrentPosition(this.changeLocation);
    }
    else if(this.props.center.lat===undefined && this.props.center.lng===undefined){
      console.log("entro al if undefined")
      navigator.geolocation.getCurrentPosition(this.changeLocation);
    } else {
      console.log("entro al no undefined")
      this.setState({
        center:this.props.center,
        marker:(<Marker
          lat={this.props.center.lat}
          lng={this.props.center.lng}
          name="location"
          color="#78065D"
        />),
        loading: false
      })
    }
  }

  setMarker(lat,lng){
    this.setState({marker:(
      <Marker
      lat={lat}
      lng={lng}
      text="location"
      color="#78065D"
    />)
  })
  }

  handleClick = (event)=>{
    this.props.handleLocationChanged(event.lat,event.lng)
    this.setMarker(event.lat,event.lng)
    
  }

  componentDidMount(){
    console.log(this.props)
    this.setInitialCenter()
  }

  // componentDidUpdate(){
  //   this.setInitialCenter()
  // }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '40vh', width: '100%' }}>
        {!this.state.loading && 
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBBttfmgcM1Gbs4yE80_uDPkbGjANPwbN0"}}
        // defaultCenter={this.state.center}
        center={this.state.center}
        defaultZoom={16}
        onClick={this.handleClick}
      >
          {this.state.marker}
      </GoogleMapReact>}
      {this.state.loading && 
      <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
      </div>
      }
      </div>
    );
  }
}

export default SimpleMap;