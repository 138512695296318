import "./InformationCard.scss";
import CurrencyFormat from "react-currency-format";


const InformationCard = (prop) => {
    var info = prop["prop"];
  return (
    <div className="row">
      <div className="col-3">
          <img src={info.imagen} alt="" className="img-card-information"/>
      </div>
      <div className="col information-container">
          <h5>{info.title}</h5>
          <h2>
          <CurrencyFormat
                value={Math.round(info.value)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
           </h2>
          <h5>{info.description}</h5>
      </div>
    </div>
  );
};

export default InformationCard;
