import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut
  } from "../globals";
  
    const URL_FINAL = URL_DOMAIN + URL_P2[2];

    const URL_PRODUCT = URL_DOMAIN + URL_P2[2];


    const supervisorId = localStorage.getItem("fk_supervisor");
  
  /**
   * Franchises
   * @returns
   */
  
  const getProducts = async () => {
    return fetch(
      URL_FINAL +
        "/products-of-supervisor?fk_supervisor=" +
        supervisorId,
      requestOptionsGet
    ).then(getJSON);
  };
  
  const getProductById = async (id) => {
    return fetch(
      URL_FINAL + "/get-product?fk_product=" + id,
      requestOptionsGet
    ).then(getJSON);
  };
  
  const postProduct = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_FINAL + "/create-product", requestOptionsPost).then(
      getJSON
    );
  };
  
  const putProduct = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-product", requestOptionsPut).then(
      getJSON
    );
  };
  const uploadProducts = async  (formData) =>{
      requestOptionsPost.body = formData;
      return fetch(
          URL_PRODUCT + "/upload-initial-products", requestOptionsPost
      ).then(getJSON);
  }
  export {getProducts, getProductById, postProduct, putProduct, uploadProducts};
  