import React, { useEffect, useState } from "react";
import "../../../index.scss";
import "./InvoiceSchemaForm.scss";

import { getFranchises } from "../../../services/utilsFranchise/utilsFranchise";
import {
  postInvoiceSchema,
  getInvoiceSchemaById,
  putInvoiceSchema,
  getFranchisesByInvoiceSchema,
  addFranchiseToInvoiceSchema,
  removeFranchiseToInvoiceSchema,
} from "../../../services/utilsInvoiceSchema/utilsInvoiceSchema";
import { FormattedMessage } from "react-intl";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Snackbar,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Alert } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import deleteIcon from "../../../assets/icons/baseline-close-24px.svg";

const InvoiceSchemaForm = () => {
  const [franchisesListInput, setfranchisesListInput] = useState([]);

  const [franchisesList, setfranchisesList] = useState([]);

  const [formTitle, setFormTitle] = useState("createNewInvoiceSchema");

  const [submitLabel, setSubmitLabel] = useState("createInvoiceSchemaLbl");

  const [inEditSize, setInEditSize] = useState("section-body col");

  const [invoiceSchema, setInvoiceSchema] = useState({});

  const [selectedDate, handleDateChange] = useState(new Date());

  const location = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const handleInputChangeInner = (event) => {
    setInvoiceSchema({
      ...invoiceSchema,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckBox = (event) => {
    setInvoiceSchema({
      ...invoiceSchema,
      [event.target.name]: event.target.checked,
    });
  };

  const fetchData = async () => {
    let invoiceSchemaId = localStorage.getItem("fk_invoice_schema");

    if (location.pathname.includes("edit")) {
      getInvoiceSchemaById(invoiceSchemaId).then((data) => {
        setInvoiceSchema(data["invoice_schema"]);
        setFormTitle("editNewInvoiceSchema");
        setSubmitLabel("saveInvoiceSchemaLbl");
      });
      getFranchises().then((data) => {
        setfranchisesListInput(data["franchises"]);
        //setLoading(false);
      });

      getFranchisesByInvoiceSchema(invoiceSchemaId).then((data) => {
        setfranchisesList(data);
      });
    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    let formDataBody = new FormData();

    const supervisorId = localStorage.getItem("fk_supervisor");
    for (const key in invoiceSchema) {
      formDataBody.append(key, invoiceSchema[key]);
      if (key === "fake") {
        formDataBody.append("fake", invoiceSchema[key] === 1 ? 1 : 0);
      }
    }

    if (location.pathname.includes("edit")) {
      let invoiceSchemaId = localStorage.getItem("fk_invoice_schema");

      formDataBody.append("id", invoiceSchemaId);
      putInvoiceSchema(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSnackbarMessage(
            <Alert severity="success">
              Se ha actualizado la resolución con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }


      });
    } else {
      formDataBody.append("fk_supervisor", supervisorId);
      postInvoiceSchema(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSnackbarMessage(
            <Alert severity="success">
              Se ha creado la resolución con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          /**
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          ); */
          setSnackbarMessage(
            <Alert severity="error">Error: Ya existe una resolución de facturación con ese número</Alert>
          );
          setOpenSnackbar(true);
        }
        //setTimeout(6000, setOpenSnackbar(false));
      });
    }
  };
  const handleAddFranchise = (event) => {
    event.preventDefault();
    //console.log("this is add franchise", event.target.value)
    let invoiceSchemaId = localStorage.getItem("fk_invoice_schema");
    let franchiseId = event.target.value;
    var formdata = new FormData();
    formdata.append("fk_franchise", franchiseId);
    formdata.append("fk_invoice_schema", invoiceSchemaId);
    addFranchiseToInvoiceSchema(formdata).then((data) => {
      fetchData();
    });
  };

  const removeRelateFranchise = (franchiseToRemove) => {
    let invoiceSchemaId = localStorage.getItem("fk_invoice_schema");
    let franchiseId = franchiseToRemove.id;
    var formdata = new FormData();
    formdata.append("fk_franchise", franchiseId);
    formdata.append("fk_invoice_schema", invoiceSchemaId);
    removeFranchiseToInvoiceSchema(formdata).then((data) => {
      fetchData();
    });
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div className="row">
      <main className={inEditSize} id="main-section">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {snackbarMessage}
        </Snackbar>
        <h1 className="section-title">
          <FormattedMessage id={formTitle} />
        </h1>
        <article>
          <form
            action=""
            onSubmit={handleSubmit}
            className="row padding-section"
          >
            <div className="col-6">
              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="nameComercialLbl" />}
                name="commercial_name"
                onChange={handleInputChangeInner}
                value={
                  invoiceSchema.commercial_name
                    ? invoiceSchema.commercial_name
                    : ""
                }
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="genericTaxLbl" />}
                name="generic_tax"
                type="number"
                onChange={handleInputChangeInner}
                value={
                  invoiceSchema.generic_tax ? invoiceSchema.generic_tax : ""
                }
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="phoneLbl" />}
                name="phone"
                onChange={handleInputChangeInner}
                value={invoiceSchema.phone ? invoiceSchema.phone : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="numberLbl" />}
                name="number"
                onChange={handleInputChangeInner}
                value={invoiceSchema.number ? invoiceSchema.number : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="minValuelLbl" />}
                name="min_value"
                type="number"
                onChange={handleInputChangeInner}
                value={invoiceSchema.min_value ? invoiceSchema.min_value : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="initialValue" />}
                type="number"
                name="start_value"
                onChange={handleInputChangeInner}
                value={
                  invoiceSchema.start_value ? invoiceSchema.start_value : ""
                }
              />
              <TextField
                required
                id="date"
                label={<FormattedMessage id="aprovalDateLbl" />}
                type="date"
                name="date_approved"
                className="form-control input-margin"
                onChange={handleInputChangeInner}
                value={
                  invoiceSchema.date_approved ? invoiceSchema.date_approved : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-6">
              <TextField
                className="form-control input-margin"
                label={<FormattedMessage id="prefixLbl" />}
                name="preffix"
                onChange={handleInputChangeInner}
                value={invoiceSchema.preffix ? invoiceSchema.preffix : ""}
              />

              <TextField
                className="form-control input-margin"
                label={<FormattedMessage id="extraLbl" />}
                name="extra"
                onChange={handleInputChangeInner}
                multiline
                value={invoiceSchema.extra ? invoiceSchema.extra : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="addressLbl" />}
                name="address"
                onChange={handleInputChangeInner}
                value={invoiceSchema.address ? invoiceSchema.address : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="nitLbl" />}
                name="nit"
                onChange={handleInputChangeInner}
                value={invoiceSchema.nit ? invoiceSchema.nit : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="maxValueLbl" />}
                name="max_value"
                type="number"
                onChange={handleInputChangeInner}
                value={invoiceSchema.max_value ? invoiceSchema.max_value : ""}
              />

              <TextField
                required
                className="form-control input-margin"
                label={<FormattedMessage id="ciiuLbl" />}
                name="ciiu"
                onChange={handleInputChangeInner}
                value={invoiceSchema.ciiu ? invoiceSchema.ciiu : ""}
              />
              <br />
              <FormControlLabel
                onChange={handleChangeCheckBox}
                control={
                  <Checkbox
                    checked={
                      invoiceSchema.fake ? Boolean(invoiceSchema.fake) : false
                    }
                    name="fake"
                  />
                }
                label={<FormattedMessage id="isPayrollLbl" />}
              />
            </div>
            <div className="row justify-content-end">
              <div className="col-12 d-flex justify-content-end">
                <Link className="" to={`/puntos-de-venta`}>
                  <button className="btn btn-outline-primary m-2">
                    <FormattedMessage id="cancelLbl" />
                  </button>
                </Link>
                <button className="btn btn-primary m-2" type="submit">
                  <FormattedMessage id={submitLabel} />
                </button>
                {/* </Link> */}
              </div>
            </div>
          </form>
        </article>
      </main>
      {location.pathname.includes("edit") && (
        <aside className="section-body col-3 aside-section">
          {/**Lests create the form to add franchise */}
          <h2 className="section-title">
            <FormattedMessage id={"addFranchiseLbl"} />
          </h2>
          <article>
            <form
              action=""
              onSubmit={handleAddFranchise}
              className="row padding-section"
            >
              <label
                className="form-label"
                htmlFor="franchiseInput"
                id="relatedFranchise"
              >
                <FormattedMessage id="relateLbl" />
              </label>
              <Select
                required
                value={0}
                name="fk_franchise"
                onChange={handleAddFranchise}
                className="worker-form-select"
                id="franchiseInput"
              >
                <MenuItem key={0} value={0}>
                  Select One...
                </MenuItem>
                {franchisesListInput &&
                  franchisesListInput.map((franchise, i) => (
                    <MenuItem key={i} value={franchise.id}>
                      {franchise.name}
                    </MenuItem>
                  ))}
              </Select>
            </form>
            {franchisesList && (
              <article>
                {franchisesList.map((franchise, i) => (
                  <div className="row ingredient-item-container">
                    <div className="col">
                      <div className="row">
                        <p className="ingredient-name no-margin">
                          {franchise.name}
                        </p>
                        <p className="ingredient-quantity no-margin">
                          {franchise.franchise_type.name}
                        </p>
                      </div>
                    </div>
                    <div className="col-4 margin-auto">
                      <div className="grid-center">
                        <a
                          href="/"
                          className="ingredient-icon"
                          onClick={(event, i) => {
                            event.preventDefault();
                            removeRelateFranchise(franchise);
                          }}
                        >
                          <img src={deleteIcon} alt="" className="" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </article>
            )}
          </article>
        </aside>
      )}
    </div>
  );
};

export default InvoiceSchemaForm;
