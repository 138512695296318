import { useEffect, useState } from "react";
import useUser from "./useUser";

const LogInFormHook = (schema) => {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState("");
  const { isLogged, logIn, worngCredential, setCredentialStatus } = useUser();

  //Vamos a manaejar que cuando se haga click hacemos login
  /** 
  useEffect(() => {
    if (isLogged) {
      //window.location.reload();
    }else{
      console.log("WORNG CREDENTIAL", worngCredential)
    }
  }, [isLogged,worngCredential]);
  */
  const handleSubmit = (event) => {
    event.preventDefault();
    const { error } = validate();
    if (!error) {
      logIn(inputs);
    } else {
      
      setErrors(error);
    }
  };

  const handleInputChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const validate = () => {
    return schema.validate(inputs);
  };

  return { handleSubmit, handleInputChange, errors, worngCredential, setCredentialStatus };
};

export default LogInFormHook;
