import React, {useEffect, useRef, useState} from "react";
import "./ProdDropzone/AddProducts.css";
import ProductListPage from "../Product/ProductListPage/ProductListPage";
import ProductCategoryListPage from "../ProductCategory/ProductCategoryListPage/ProductCategoryListPage";
import IngredientListPage from "../Ingredient/IngredientListPage/IngredientListPage";

import CustomTab from "../../components/CustomTab/CustomTab";

import FranchisePage from "../Franchise/FranchisePage/FranchisePage";
import "../../index.scss";
import {FormattedMessage} from "react-intl";
import {URL_DOMAIN, URL_P2, URL_REPORT} from "../../services/globals";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {MenuItem, Select} from "@material-ui/core";
import DownloadLinkCustom from "../../components/DownloadLinkCustom/DownloadLinkCustom";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import DropzoneFile from "../../components/DropzoneFile/DropzoneFile";
import ProdDropzone from "./ProdDropzone/ProdDropzone";
import {getFranchiseTypes} from "../../services/utilsFranchiseType/utilsFranchiseType";
import {uploadProducts} from "../../services/utilsProduct/utilsProduct";
import {useDetectOutsideClick} from "../../components/useDetectOutsideClick";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";

export default function MyProductPage() {

    const [importOpen, setImportOpen] = useState(false);

    const components = [
        {
            navTitle: "productsNav",
            component: <ProductListPage/>,
            url: "productos",
            buttonComponenet: <PopupMenu setImportOpen={setImportOpen}/>
        },

        {
            navTitle: "categoriesNav",
            component: <ProductCategoryListPage/>,
            url: "categorias-de-producto",
        },
        {
            navTitle: "ingredientsNav",
            component: <IngredientListPage/>,
            url: "ingredientes",
        },
    ];


    return (
        <div>
            <main className="section-body">
                <div className="flex space">
                    <h1 className="section-title">
                        <FormattedMessage id="productTitle"/>
                    </h1>

                </div>

                {components && <CustomTab props={components}/>}
            </main>
        </div>
    );
}

function PopupMenu(props) {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <div className="container">
            <div className="menu-container">
                <button onClick={onClick} className="menu-trigger">
                    <Fab size="small" className="fab" color="secondary" aria-label="edit">
                        <AddIcon className="fab-icon" />
                    </Fab>
                </button>
                <nav
                    ref={dropdownRef}
                    className={`menu ${isActive ? "active" : "inactive"}`}
                >
                    <ul>
                        <li>
                            <a href="productos/create">Nuevo producto</a>
                        </li>
                        <li>
                            <a href="#"  onClick={() => props.setImportOpen(true)}>Importar productos</a>
                        </li>

                    </ul>
                </nav>
            </div>
        </div>
    );
}




