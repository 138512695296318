import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
} from "@mui/material";
import { getCritialProvidersBySupervisor } from "../../services/utilsProvider/utilsProvider";
import { getFranchises } from "../../services/utilsFranchise/utilsFranchise";

import { DataGrid } from "@mui/x-data-grid";
import "./StockPage.scss";
import LinearProgress from "@material-ui/core/LinearProgress";

import SearchBar from "../../components/searchBar/searchBar";

const CriticalProducts = () => {
  const [productsData, setProductsData] = useState([]);
  const [productDataDefatult, setProductDataDefault] = useState([]);

  const [franchisesData, setFranshisesData] = useState([]);

  const [franchiseSelected, setFranchiseSelected] = useState(0);

  const [loadingTable, setLoadingTable] = useState(false);

  let rowsId = 1;
  const columns = [
    { field: "name", headerName: "Producto", width: 330 },
    { field: "franchiseName", headerName: "Punto de venta", width: 130 },
    { field: "provider", headerName: "Proveedores", width: 250 },
    {
      field: "in_stock",
      headerName: "En tienda",
      type: "number",
      width: 90,
    },
    {
      field: "sales",
      headerName: "Ventas",
      type: "number",
      width: 90,
    },
    {
      field: "sales_days",
      headerName: "Ventas / dia",
      type: "number",
      width: 110,
    },
    {
      field: "suggested_order",
      headerName: "Pedido sugerido",
      type: "number",
      width: 150,
    },
  ];

  let dataFake = [
    {
      name: "MIA ENTERPRISE S.A.S",
      nit: "900865037",
      products: [
        [
          {
            name: "Alfajor 75gr (MIAS) Chocolate",
            sku: "065",
            "Carrera 15": {
              in_stock: 1.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 1.0,
              stock_policy: 1,
              suggested_order: -1.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -8.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -8.0,
              stock_policy: 1,
              suggested_order: 8.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Alfajor Keto 50gr (SIN REMORDIMIENTO)",
            sku: "0781159063848",
            "Carrera 15": {
              in_stock: 6.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 6.0,
              stock_policy: 1,
              suggested_order: -6.0,
              critical: false,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -1.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -1.0,
              stock_policy: 1,
              suggested_order: 1.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -5.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -5.0,
              stock_policy: 1,
              suggested_order: 5.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 2.0,
              stock_policy: 1,
              suggested_order: -2.0,
              critical: false,
            },
          },
          {
            name: "Bolo Pote 500gr (MIAS) Arequipe",
            sku: "061",
            "Carrera 15": {
              in_stock: -5.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -5.0,
              stock_policy: 1,
              suggested_order: 5.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -15.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -15.0,
              stock_policy: 1,
              suggested_order: 16.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -7.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -7.0,
              stock_policy: 1,
              suggested_order: 7.0,
              critical: true,
            },
            Bodega: {
              in_stock: -17.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -17.0,
              stock_policy: 1,
              suggested_order: 18.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -19.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -19.0,
              stock_policy: 1,
              suggested_order: 19.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -23.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -23.0,
              stock_policy: 1,
              suggested_order: 24.0,
              critical: true,
            },
          },
          {
            name: "Bolo Pote 500gr (MIAS) Chocolate",
            sku: "062",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -28.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -14.0,
              stock_policy: 1,
              suggested_order: 16.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -5.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -5.0,
              stock_policy: 1,
              suggested_order: 5.0,
              critical: true,
            },
            Bodega: {
              in_stock: -15.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -15.0,
              stock_policy: 1,
              suggested_order: 15.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -27.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -27.0,
              stock_policy: 1,
              suggested_order: 27.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -30.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -30.0,
              stock_policy: 1,
              suggested_order: 30.0,
              critical: true,
            },
          },
          {
            name: "Bolo Pote 500gr (MIAS) Frutos Rojos",
            sku: "0636",
            "Carrera 15": {
              in_stock: -3.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -3.0,
              stock_policy: 1,
              suggested_order: 3.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -14.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -7.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -3.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -3.0,
              stock_policy: 1,
              suggested_order: 3.0,
              critical: true,
            },
            Bodega: {
              in_stock: -13.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -13.0,
              stock_policy: 1,
              suggested_order: 13.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -19.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -19.0,
              stock_policy: 1,
              suggested_order: 19.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -21.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -21.0,
              stock_policy: 1,
              suggested_order: 22.0,
              critical: true,
            },
          },
          {
            name: "Bolo Pote 500gr (MIAS) Vainilla",
            sku: "1083",
            "Carrera 15": {
              in_stock: 2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 2.0,
              stock_policy: 1,
              suggested_order: -2.0,
              critical: false,
            },
            "Calle 140": {
              in_stock: -8.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -8.0,
              stock_policy: 1,
              suggested_order: 8.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -6.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -6.0,
              stock_policy: 1,
              suggested_order: 6.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -13.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -13.0,
              stock_policy: 1,
              suggested_order: 13.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -24.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -24.0,
              stock_policy: 1,
              suggested_order: 24.0,
              critical: true,
            },
          },
          {
            name: "Cake Explosion 220gr (MIAS) Caramelo",
            sku: "063",
            "Carrera 15": {
              in_stock: -3.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -3.0,
              stock_policy: 1,
              suggested_order: 3.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -9.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -9.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -18.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -18.0,
              stock_policy: 1,
              suggested_order: 19.0,
              critical: true,
            },
            Bodega: {
              in_stock: -6.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -6.0,
              stock_policy: 1,
              suggested_order: 7.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -16.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -16.0,
              stock_policy: 1,
              suggested_order: 16.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -23.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -23.0,
              stock_policy: 1,
              suggested_order: 23.0,
              critical: true,
            },
          },
          {
            name: "Cake Explosion 220gr (MIAS) Red Velvet",
            sku: "0006412",
            "Carrera 15": {
              in_stock: 2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 2.0,
              stock_policy: 1,
              suggested_order: -2.0,
              critical: false,
            },
            "Calle 140": {
              in_stock: -10.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -10.0,
              stock_policy: 1,
              suggested_order: 11.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -22.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -22.0,
              stock_policy: 1,
              suggested_order: 22.0,
              critical: true,
            },
            Bodega: {
              in_stock: -10.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -10.0,
              stock_policy: 1,
              suggested_order: 11.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -9.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -9.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -16.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -8.0,
              stock_policy: 1,
              suggested_order: 10.0,
              critical: true,
            },
          },
          {
            name: "Leche Condensada 400gr (MIAS)",
            sku: "064",
            "Carrera 15": {
              in_stock: 2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 2.0,
              stock_policy: 1,
              suggested_order: -2.0,
              critical: false,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -2.0,
              stock_policy: 1,
              suggested_order: 2.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Torta Triangulo 250gr (MIAS) Chocolate",
            sku: "067",
            "Carrera 15": {
              in_stock: -5.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -5.0,
              stock_policy: 1,
              suggested_order: 5.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -20.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -10.0,
              stock_policy: 1,
              suggested_order: 12.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -27.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -9.0,
              stock_policy: 1,
              suggested_order: 12.0,
              critical: true,
            },
            Bodega: {
              in_stock: -14.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -14.0,
              stock_policy: 1,
              suggested_order: 15.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -9.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -9.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -70.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -35.0,
              stock_policy: 1,
              suggested_order: 37.0,
              critical: true,
            },
          },
          {
            name: "Torta Triangulo 250gr (MIAS) Vainilla",
            sku: "02105",
            "Carrera 15": {
              in_stock: 4.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 4.0,
              stock_policy: 1,
              suggested_order: -4.0,
              critical: false,
            },
            "Calle 140": {
              in_stock: -20.0,
              sales_dates: 5.0,
              sales_day: 5.0,
              stock_days: -4.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -17.0,
              sales_dates: 4.0,
              sales_day: 4.0,
              stock_days: -4.25,
              stock_policy: 1,
              suggested_order: 8.25,
              critical: true,
            },
            Bodega: {
              in_stock: -21.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -21.0,
              stock_policy: 1,
              suggested_order: 21.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -16.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -16.0,
              stock_policy: 1,
              suggested_order: 16.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -53.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -53.0,
              stock_policy: 1,
              suggested_order: 54.0,
              critical: true,
            },
          },
          {
            name: "Volcan 145gr (MIAS) Chocolate",
            sku: "066",
            "Carrera 15": {
              in_stock: 1.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 1.0,
              stock_policy: 1,
              suggested_order: -1.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -16.0,
              sales_dates: 6.0,
              sales_day: 6.0,
              stock_days: -2.6666666666666665,
              stock_policy: 1,
              suggested_order: 8.666666666666666,
              critical: true,
            },
            "Calle 90": {
              in_stock: -28.0,
              sales_dates: 6.0,
              sales_day: 6.0,
              stock_days: -4.666666666666667,
              stock_policy: 1,
              suggested_order: 10.666666666666668,
              critical: true,
            },
            Bodega: {
              in_stock: -28.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -28.0,
              stock_policy: 1,
              suggested_order: 29.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -15.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -15.0,
              stock_policy: 1,
              suggested_order: 15.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -81.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -81.0,
              stock_policy: 1,
              suggested_order: 81.0,
              critical: true,
            },
          },
          {
            name: "Torta Choco Alfajor (MIAS) Porción (Insumo) ",
            sku: "PT-1055",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -80.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -80.0,
              stock_policy: 1,
              suggested_order: 80.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -91.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -91.0,
              stock_policy: 1,
              suggested_order: 91.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -23.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -23.0,
              stock_policy: 1,
              suggested_order: 23.0,
              critical: true,
            },
          },
          {
            name: "Torta Vainilla Alfajor (MIAS) Porcion (Insumo) ",
            sku: "PT-1069",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -65.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -65.0,
              stock_policy: 1,
              suggested_order: 65.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -39.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -39.0,
              stock_policy: 1,
              suggested_order: 39.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -10.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -10.0,
              stock_policy: 1,
              suggested_order: 10.0,
              critical: true,
            },
          },
          {
            name: "Torta Choco Flan (MIAS) Porcion (Insumo) ",
            sku: "PT-1065",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -11.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -11.0,
              stock_policy: 1,
              suggested_order: 11.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -6.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -6.0,
              stock_policy: 1,
              suggested_order: 6.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Torta Marquesa Avellana (MIAS) Porción (Insumo) ",
            sku: "PT-1085",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: -19.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -19.0,
              stock_policy: 1,
              suggested_order: 19.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Eritritol Endulzante 200g (MIAS)",
            sku: "7709178264834",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Xilitol Endulzante 200g (MIAS)",
            sku: "7709133006097",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 2.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 2.0,
              stock_policy: 1,
              suggested_order: -2.0,
              critical: false,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
          },
          {
            name: "Monk Fruit Endulzante 200g (MIAS)",
            sku: "7709178264803",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: 1.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 1.0,
              stock_policy: 1,
              suggested_order: -1.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: 1.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 1.0,
              stock_policy: 1,
              suggested_order: -1.0,
              critical: true,
            },
          },
          {
            name: "Mini Bolo Pote (MIAS) Vainilla",
            sku: "20210621",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -19.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -19.0,
              stock_policy: 1,
              suggested_order: 19.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -9.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -9.0,
              stock_policy: 1,
              suggested_order: 9.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -29.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -14.5,
              stock_policy: 1,
              suggested_order: 16.5,
              critical: true,
            },
          },
          {
            name: "Mini Bolo Pote (MIAS) Chocolate",
            sku: "20212106",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -25.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -8.333333333333334,
              stock_policy: 1,
              suggested_order: 11.333333333333334,
              critical: true,
            },
            "Calle 90": {
              in_stock: -14.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: -14.0,
              stock_policy: 1,
              suggested_order: 14.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -53.0,
              sales_dates: 4.0,
              sales_day: 4.0,
              stock_days: -13.25,
              stock_policy: 1,
              suggested_order: 17.25,
              critical: true,
            },
          },
          {
            name: "Mini Bolo Pote (MIAS) Arequipe",
            sku: "21296514",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -27.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -27.0,
              stock_policy: 1,
              suggested_order: 28.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -9.0,
              sales_dates: 4.0,
              sales_day: 4.0,
              stock_days: -2.25,
              stock_policy: 1,
              suggested_order: 6.25,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -39.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -13.0,
              stock_policy: 1,
              suggested_order: 16.0,
              critical: true,
            },
          },
          {
            name: "Torta Triangulo 250gr (MIAS) Chocolate Brigadeiro",
            sku: "2608",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -33.0,
              sales_dates: 2.0,
              sales_day: 2.0,
              stock_days: -16.5,
              stock_policy: 1,
              suggested_order: 18.5,
              critical: true,
            },
            "Calle 90": {
              in_stock: -19.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -6.333333333333333,
              stock_policy: 1,
              suggested_order: 9.333333333333332,
              critical: true,
            },
            Bodega: {
              in_stock: -12.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -4.0,
              stock_policy: 1,
              suggested_order: 7.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -23.0,
              sales_dates: 3.0,
              sales_day: 3.0,
              stock_days: -7.666666666666667,
              stock_policy: 1,
              suggested_order: 10.666666666666668,
              critical: true,
            },
          },
          {
            name: "Mini Bolo Pote (MIAS) Frutos Rojos",
            sku: "2102685",
            "Carrera 15": {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 140": {
              in_stock: -28.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -28.0,
              stock_policy: 1,
              suggested_order: 29.0,
              critical: true,
            },
            "Calle 90": {
              in_stock: -7.0,
              sales_dates: 1.0,
              sales_day: 1.0,
              stock_days: -7.0,
              stock_policy: 1,
              suggested_order: 8.0,
              critical: true,
            },
            Bodega: {
              in_stock: 0.0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0.0,
              stock_policy: 1,
              suggested_order: 0.0,
              critical: true,
            },
            "Cafetería de la 140": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Calle 71": {
              in_stock: 0,
              sales_dates: 0,
              sales_day: 0.0,
              stock_days: 0,
              stock_policy: 12,
              suggested_order: 0.0,
              critical: true,
            },
            "Tienda 71": {
              in_stock: -26.0,
              sales_dates: 6.0,
              sales_day: 6.0,
              stock_days: -4.333333333333333,
              stock_policy: 1,
              suggested_order: 10.333333333333332,
              critical: true,
            },
          },
        ],
      ],
    },
  ];

  let transformProducts = (provider) => {
    let nameProvider = provider.name;
    let productsFranchises = [];
    provider.products[0].map((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (key === "name" || key === "sku") {
        } else {
          let productObj = {
            id: rowsId,
            name: product["name"],
            sku: product["sku"],
            provider: nameProvider,
            franchiseName: key,
            in_stock: value.in_stock,
            sales: value.sales_dates,
            sales_days: value.sales_day,
            suggested_order: value.suggested_order,
            [key]: value,
          };
          rowsId++;
          productsFranchises.push(productObj);
        }
      });
    });

    return productsFranchises;
  };

  const handleChangeFranchise = (event) => {
    setFranchiseSelected(event.target.value);
    if (event.target.value === 0) {
      setProductsData(productDataDefatult);
    } else {
      let franchiseFound = franchisesData.find(
        (franchise) => franchise.id === event.target.value
      );
      let filteredProducts = productDataDefatult.filter((product) => {
        if (product.franchiseName === franchiseFound.name) {
          return product;
        }
      });
      setProductsData(filteredProducts);
    }
  };

  const onChangeSearch = async (event) => {
    let input = event.target.value;
    const filteredProducts = productDataDefatult.filter((product) => {
      return (
        product.name.toLowerCase().includes(input.toLowerCase()) ||
        product.provider.toLowerCase().includes(input.toLowerCase())
      );
    });
    setProductsData(filteredProducts);
  };

  const fetchData = () => {
    let fk_supervisor = localStorage.getItem("fk_supervisor");
    setLoadingTable(true);
    getFranchises().then((data) => {
      console.log(data, "fetchfranchises");
      setFranshisesData(data["franchises"]);
    });
    
    getCritialProvidersBySupervisor(fk_supervisor).then((data)=>{
        console.log("This is the data", data);
        let newData = data.flatMap((provider) => transformProducts(provider));
        setProductsData(newData);
        setProductDataDefault(newData);
        setLoadingTable(false);
        // setProductsData(data);
        // setCriticalProductsProvider(data.products[0])
    });
    
    
    
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="page-container">
        <FormControl variant="standard" style={{ width: "20%", margin: "8px" }}>
          <InputLabel id="demo-simple-select-standard-label" color="secondary">
            Punto de venta
          </InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={franchiseSelected}
            onChange={handleChangeFranchise}
            label="Punto de venta"
            color="secondary"
            className="input"
          >
            <MenuItem value={0} key={0}>
              Todas
            </MenuItem>
            {franchisesData.map((franchise, indexFranchise) => {
              return (
                <MenuItem value={franchise.id} key={indexFranchise}>
                  {franchise.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" style={{ width: "76%", margin: "8px" }}>
          <TextField
            id="standard-basic"
            label="Producto o proveedor"
            variant="standard"
            color="secondary"
            onChange={onChangeSearch}
          />
        </FormControl>
        {loadingTable && <LinearProgress/>}
        {!loadingTable && <div className="grid-cointainder">
          <div style={{ height: 650, width: "100%" }}>
            <DataGrid
              rows={productsData}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default CriticalProducts;
