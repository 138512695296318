import React, { useEffect, useState } from "react";
import "../../../index.scss";
import "./OperatorForm.scss";
import OperatorDefaultImg from "../../../assets/img/default/operario.jpg";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import { getFranchises } from "../../../services/utilsFranchise/utilsFranchise";
import { FormattedMessage } from "react-intl";
import {
  getOperatorById,
  postOperator,
  putOperator,
} from "../../../services/utilsOperator/utilsOperator";
import { TextField, Select, MenuItem, Snackbar} from "@material-ui/core";
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";

export const OperatorForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  let newProfilePicture;

  const [franchiseList, setFranchiseList] = useState([]);

  const [imgSource, setImgSource] = useState(OperatorDefaultImg);

  const [operator, setOperator] = useState({ fk_supervisor: 23 });

  const [loading, setLoading] = useState(true);

  const [passwordEdited, setPasswordEdited] = useState({
    password: true,
    repeat_password: true,
  });

  const [formTitle, setFormTitle] = useState("createNewWorker");

  const [submitLabel, setSubmitLabel] = useState("createWorkerLbl");

  const [submitted, setSubmitted] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const location = useLocation();

  const passwordsMatch = function () {
    return operator.password === operator.repeat_password;
  };

  const [snackbarMessage, setSnackbarMessage] = useState(<Alert severity="success">NONE</Alert>)

  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const [successSnackbar, setSuccessSnackbar] = useState(false)

  const [formInDemo, setFormInDemo] = useState("section-body");
  const history = useHistory();
  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async () => {
    console.log("entré a la form");
    getFranchises().then((data) => {
      console.log(data, "fetchfranchises");
      setFranchiseList(data["franchises"]);
      setLoading(false);
    });
    if (location.pathname.includes("demo")){
      setFormInDemo("section-in-demo");
    }
    if (location.pathname.includes("edit")) {
      let operatorId = localStorage.getItem("fk_operator");
      setPasswordEdited({ password: false, repeat_password: false });
      setFormTitle("editWorker");
      setSubmitLabel("saveWorkerLbl");

      getOperatorById(operatorId).then((data) => {
        console.log(data, "fetch operator");
        let operatorData = data.operator;
        let userData = operatorData.user;
        setOperator({
          username: userData.username,
          first_name: userData.first_name,
          last_name: userData.last_name,
          password: "        ",
          repeat_password: "        ",
          fk_franchise: operatorData.franchise.id,
          email: userData.email,
          pin: operatorData.pin
        });
        operatorData.image
          ? setImgSource(operatorData.image)
          : setImgSource(imgSource);
      });
    } 
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setOperator({ ...operator, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleFirstTimePasswordEdit = (event) => {
    setOperator({
      ...operator,
      [event.target.name]: event.target.value.trim(),
    });
    setPasswordEdited({ ...passwordEdited, [event.target.name]: true });
  };

  const handleInputChangeInner = (event) => {
    setOperator({ ...operator, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in operator) {
      if (typeof operator[key] == File) {
        formDataBody.append(key, operator[key], "profilePicture.jpg");
      } else {
        formDataBody.append(key, operator[key]);
      }
    }
    if (location.pathname.includes("edit")) {
      let operatorId = localStorage.getItem("fk_operator");
      formDataBody.append("fk_operator", operatorId);
      console.log(formDataBody);
      putOperator(formDataBody).then((data) => {
        console.log(data);
        if(data.error === undefined){
          setSuccessSnackbar(true)
          setSnackbarMessage(<Alert severity="success">Se ha actualizado el operario con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false)
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    } else {
      if (passwordsMatch()) {
        postOperator(formDataBody).then((data) => {
          console.log(data)
          if(data.error === undefined){
            setSuccessSnackbar(true)
            setSnackbarMessage(<Alert severity="success">Se ha creado el operario con éxito</Alert>);
            setOpenSnackbar(true);
          } else {
            setSuccessSnackbar(false)
            setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        });
      } else {
        setSuccessSnackbar(false)
        setSnackbarMessage(<Alert severity="error">Error: Las contraseñas no son iguales</Alert>);
        setOpenSnackbar(true);
        setSubmitted(false)
      }
    }
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleSnackbarClose = (event, reason, success=false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar){
      history.push("/trabajadores");
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className={formInDemo}>
      <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}

                >
                 {snackbarMessage}
                </Snackbar>
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && (
        <form
          onSubmit={handleSubmit}
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="d-flex justify-content-center mb-4">
            <img className="profile-picture" src={imgSource} alt="Avatar" />
            <div className="icon-container d-flex justify-content-center align-items-center">
              <img
                className="icon"
                src={EditIcon}
                alt="edit_icon"
                onClick={handleEditImageClick}
              />
            </div>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleOnImageChange}
            name="file"
            hidden="hidden"
            accept="image/*"
          />
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="userLbl" />}
                  value={operator.username ? operator.username : ""}
                  name="username"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="emailLbl" />}
                  value={operator.email ? operator.email : ""}
                  name="email"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="firstNameLbl" />}
                  value={operator.first_name ? operator.first_name : ""}
                  name="first_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="lastNameLbl" />}
                  value={operator.last_name ? operator.last_name : ""}
                  name="last_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>

          {!location.pathname.includes("edit") && (
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="standard-basic"
                    label={<FormattedMessage id="passwordLbl" />}
                    value={operator.password ? operator.password : ""}
                    name="password"
                    onChange={
                      passwordEdited.password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="standard-basic"
                    label={<FormattedMessage id="repeatPasswordLbl" />}
                    value={
                      operator.repeat_password ? operator.repeat_password : ""
                    }
                    name="repeat_password"
                    onChange={
                      passwordEdited.repeat_password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
                
              </div>
            </div>
          )}


          <div className="row">
            <div className="col-6 mb-4">
              <label className="form-label" htmlFor="franchiseInput">
                <FormattedMessage id="franchiseLbl" />
              </label>
              <Select
                required
                value={operator.fk_franchise ? operator.fk_franchise : ""}
                name="fk_franchise"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="franchiseInput"
              >
                {franchiseList &&
                  franchiseList.map((franchise, i) => (
                    <MenuItem key={i} value={franchise.id}>
                      {franchise.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <br/>
            <br/>

            <div className="col-6">
              <div className="mb-4">
                <br/>

                <TextField

                    type="number"
                    className="form-control"
                    id="standard-basic"
                    label="PIN"
                    value={
                      operator.pin ? operator.pin : ""
                    }
                    name="pin"
                    onChange={handleInputChangeInner}
                />
              </div>

            </div>
            {location.pathname.includes("demo") && (<div className="col-6 mb-4">
              <p className="mesagge-attention">¡ATENCIÓN! Guarda el usuario y la contraseña en lugar seguro. Lo necesitarás para probar el perfil de trabajador en la aplicación "Kurúah" para dispositivos Android.</p>
            </div>)}
          </div>
          <div className="row justify-content-end">
            <div className="col-12 d-flex justify-content-end">
              {!location.pathname.includes("demo") && (              <Link className="" to={`/trabajadores`}>
                <button className="btn btn-outline-primary m-2">
                  <FormattedMessage id="cancelLbl" />
                </button>
              </Link>)}

              {/* <Link className="" to={`/trabajadores`}> */}
              <button
                className="btn btn-primary m-2"
                type="submit"
                disabled={submitted}
              >
                {!submitted && <FormattedMessage id={submitLabel} />}
                {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </main>
  );
};

export default OperatorForm;
