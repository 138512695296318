import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[11];

const getAllProvidersBySupervisor = (id) =>{
  return fetch(
      URL_FINAL + "/get-all-providers-by-supervior?fk_supervisor=" + id,
      requestOptionsGet
  ).then(getJSON);
};


const getCritialProvidersBySupervisor = (id) =>{
    return fetch(
        URL_FINAL + `/get-critical-provider?fk_supervisor=${id}`,
        requestOptionsGet
    ).then(getJSON);
};




const getAllProductsByProvider = (id, startDate, endDay) =>{
    return fetch(
        URL_FINAL + `/get-products-analysis-of-provider?fk_provider=${id}&start_date=${startDate}&end_date=${endDay}`, requestOptionsGet
    ).then(getJSON);
};

export {getAllProvidersBySupervisor, getAllProductsByProvider, getCritialProvidersBySupervisor}