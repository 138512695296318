import {
    URL_DOMAIN,
    URL_DOMAIN_ROOT,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";

  const URL_INVENTORY = URL_DOMAIN + URL_P2[10];

  const URL_PRODUCT = URL_DOMAIN + URL_P2[2];

  const getInventoryInfoByFranchise = async (id) =>{
    return fetch(
      URL_DOMAIN_ROOT + "/franchise-attendants/get-all-products-in-franchise-new?fk_franchise="+id,
      requestOptionsGet
    ).then(getJSON);
  }
  const getInventoryIngredientsInfoByFranchise = async (id) =>{
  return fetch(
      URL_DOMAIN_ROOT + "/franchise-attendants/get-all-ingredients-in-franchise-new?fk_franchise="+id,
      requestOptionsGet
  ).then(getJSON);
}
  const getTransactionsForProductByFranchiseDateRange = async (fk_franchise, fk_product, start_date, end_date) =>{
    return fetch(
      URL_INVENTORY + "/get-all-details-for-product-in-date-range?fk_franchise="+fk_franchise + "&fk_product="+ fk_product + "&start_date="+ start_date + "&end_date="+ end_date,
      requestOptionsGet
    ).then(getJSON);
  }

  const getProductAvailabilityInFranchise = async (fk_franchise, fk_product) =>{
    return fetch(
      URL_DOMAIN_ROOT + "/franchise-attendants/get-product-availability-in-franchise?fk_franchise="+fk_franchise+"&fk_product="+ fk_product,
      requestOptionsGet
    ).then(getJSON);
  }

  const getProudctInventoryGraph = async (fk_franchise, fk_product, start_date, end_date) =>{
    return fetch(
      URL_DOMAIN_ROOT + "/inventory-transactions/product_inventory_histogram?fk_franchise="+fk_franchise + "&fk_product="+ fk_product + "&start_date="+ start_date + "&end_date="+ end_date,
      requestOptionsGet
    ).then(getJSON);
  }

  const uploadInitialInventory = async (formData) =>{
    requestOptionsPost.body = formData; 
    return fetch(
      URL_PRODUCT + "/upload-initial-inventory", requestOptionsPost
      ).then(getJSON);
  }

  const createInventoryConfrirmation = async (formData) =>{
    requestOptionsPost.body = formData;
    requestOptionsPost.headers.append("Content-Type", "application/json");
    return fetch(
      URL_DOMAIN_ROOT + "/franchise-attendants/create-inventory-confirmation", requestOptionsPost
    ).then(getJSON);
  } 

export {getInventoryInfoByFranchise, getTransactionsForProductByFranchiseDateRange, getProductAvailabilityInFranchise, getProudctInventoryGraph, uploadInitialInventory, createInventoryConfrirmation, getInventoryIngredientsInfoByFranchise}