import "./HomePage.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useContext, useEffect, useState } from "react";
import {
  getFullBalance,
  getTableOccupancy,
  getTodaySales,
} from "../../services/utilsInformation/utilsInformation";
import { PieChartHome } from "../../components/supervisor/PieChartHome/PieChartHome";
import CurrencyFormat from "react-currency-format";
import InformationCard from "../../components/supervisor/InformationCard/InformationCard";
import LastSaleItem from "../../components/supervisor/LastSaleItem/LastSaleItem";
import TodaySalesImg from "../../assets/img/invoice.jpg";
import TodayCashImg from "../../assets/img/money.jpg";
import TodayExpenses from "../../assets/img/money_loss.jpg";
import CrownImg from "../../assets/img/crown.svg";
import foodHouseicon from "../../assets/icons/food_bank_purple_24px.svg";
import groupIcon from "../../assets/icons/group_purple_24px.svg";
import foodIcon from "../../assets/icons/fastfood_purple_24px.svg";
import Context from "../../context/UserContext";

const HomePage = () => {
  const [todaySalesCuantity, setTodaySalesCuantity] = useState("");

  const [todayCash, setTodayCash] = useState("");

  const [todayExpenses, setTodayExpenses] = useState("");

  const [bestSalesFranchise, setBestSalesFranchise] = useState({
    sales: 0,
    name: "Kurúah",
  });

  const [franchiseNumber, setFranchiseNumber] = useState("");

  const [lastSales, setLastSales] = useState([]);

  const fetchData = async () => {
    getFullBalance().then((data) => {
      console.log(data);
      setTodaySalesCuantity(data.total_today_sales);
      setTodayCash(data.total_money_available);
      setTodayExpenses(data.total_today_receipts);
      setFranchiseNumber(data.number_franchises);
      setBestSalesFranchise({
        sales: data.best_franchise_sale,
        name: data.best_franchise_name,
      });
    });

    getTableOccupancy().then((data) => {
      let fill_tables = data.total.fill_tables;
      let total_tables = data.total.total_tables;

      PieChartHome(fill_tables, total_tables);
    });

    getTodaySales().then((data) => {
      setLastSales(data.sales);
    });
  };

  let propSales = {
    value: todaySalesCuantity,
    title: "Hoy has vendido",
    description: "en " + franchiseNumber + " locales",
    imagen: TodaySalesImg,
  };

  let propExpenses = {
    value: todayExpenses,
    title: "Total gastos de hoy",
    description: "en " + franchiseNumber + " locales",
    imagen: TodayExpenses,
  };

  let propCash = {
    value: todayCash,
    title: "Efectivo disponible",
    imagen: TodayCashImg,
  };

  let day, time, dateNumber, dateMonth, dateYear;

  const getCurrentTimeDate = () => {
    let currentTimeDate = new Date();

    var weekday = new Array(7);
    weekday[0] = <FormattedMessage id="sundayLbl" />;
    weekday[1] = <FormattedMessage id="mondayLbl" />;
    weekday[2] = <FormattedMessage id="tuesdayLbl" />;
    weekday[3] = <FormattedMessage id="wednesdayLbl" />;
    weekday[4] = <FormattedMessage id="thursdayLbl" />;
    weekday[5] = <FormattedMessage id="fridayLbl" />;
    weekday[6] = <FormattedMessage id="saturdayLbl" />;

    var month = new Array(12);
    month[0] = <FormattedMessage id="janLbl" />;
    month[1] = <FormattedMessage id="febLbl" />;
    month[2] = <FormattedMessage id="marLbl" />;
    month[3] = <FormattedMessage id="aprLbl" />;
    month[4] = <FormattedMessage id="mayLbl" />;
    month[5] = <FormattedMessage id="junLbl" />;
    month[6] = <FormattedMessage id="julLbl" />;
    month[7] = <FormattedMessage id="augLbl" />;
    month[8] = <FormattedMessage id="sepLbl" />;
    month[9] = <FormattedMessage id="octLbl" />;
    month[10] = <FormattedMessage id="novLbl" />;
    month[11] = <FormattedMessage id="decLbl" />;

    var hours = currentTimeDate.getHours();

    var minutes = currentTimeDate.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    var AMPM = hours >= 12 ? "P.M." : "A.M.";

    if (hours === 12) {
      hours = 12;
    } else {
      hours = hours % 12;
    }

    var currentTime = `${hours}:${minutes} ${AMPM}`;
    var currentDay = weekday[currentTimeDate.getDay()];

    var currentDate = currentTimeDate.getDate();

    var currentMonth = month[currentTimeDate.getMonth()];
    var CurrentYear = currentTimeDate.getFullYear();

    day = currentDay;
    time = currentTime;
    dateNumber = currentDate;
    dateMonth = currentMonth;
    dateYear = CurrentYear;

    setTimeout(getCurrentTimeDate, 500);
  };

  getCurrentTimeDate();

  //const supervisorProfile = JSON.parse(localStorage.getItem("profile"))["profile"];
  const { supervisorProfile } = useContext(Context);
  const [supervisorName, setSupervisorName] = useState("Kurúah");

  useEffect(() => {
    fetchData();

    if (supervisorProfile.profile.user.last_name) {
      setSupervisorName(
        supervisorProfile.profile.user.first_name +
          " " +
          supervisorProfile.profile.user.last_name +
          "!"
      );
    } else {
      setSupervisorName(supervisorProfile.profile.user.first_name + "!");
    }
  }, [supervisorProfile]);

  return (
    <main>
      <div className="container">
        <div className="row" id="name-container">
          <h1 className="remove-org-padding">
            ¡Hola, {supervisorName}
            {/*  {supervisorProfile.user.first_name}{" "}
{supervisorProfile.user.last_name ? supervisorProfile.user.last_name : ""}!*/}
          </h1>
          <p className="remove-org-padding">
            {day} {dateNumber} {dateMonth} {dateYear} | {time}
          </p>
        </div>
        <main className="row">
          <section className="col-6 no-margin">
            <article
              className="article-container margin-boxes"
              id="today-sale-value"
            >
              <InformationCard prop={propSales}></InformationCard>
            </article>
            <article className="article-container margin-boxes sales-container">
              <h5 id="recent-sales">Ventas del dia</h5>
              <div className="scroll-overflow">
                {lastSales &&
                  lastSales.map((sale, i) => (
                    <LastSaleItem prop={sale} key={i}></LastSaleItem>
                  ))}
              </div>
            </article>
          </section>
          <section className="col-6 no-margin">
            <section id="try-for-help" className="row no-padding-try">
              <article className="col-6 no-padding-try text-center">
                <div className="chart-container article-container hidden-overflow">
                  <h6>Mejor punto de venta</h6>
                  {/**<svg className="chart-size"></svg>*/}
                  <img src={CrownImg} alt="" className="crown-img" />
                  <h5 className="best_fran_name">{bestSalesFranchise.name}</h5>
                  <p className="best_fran_sales">
                    <CurrencyFormat
                      value={bestSalesFranchise.sales}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </p>
                </div>
              </article>
              <article className="col-6 no-padding-try">
                <section id="info-part2-1">
                  <p id="fast-link">Accesos rápidos</p>
                  <div
                    className="article-container margin-boxes"
                    id="link-container"
                  >
                    <Link
                      to="/puntos-de-venta"
                      style={{ textDecoration: "none" }}
                    >
                      <a
                        href="/puntos-de-venta"
                        className="link-container-item"
                      >
                        <img src={foodHouseicon} alt="" className="menu-icon" />
                        <div className="text-block textovertialnavbar">
                          <h6>
                            {" "}
                            <FormattedMessage id="franchiseTitle" />
                          </h6>
                        </div>
                      </a>
                    </Link>
                    <Link to="/trabajadores" style={{ textDecoration: "none" }}>
                      <a href="/trabajadores" className="link-container-item">
                        <img src={groupIcon} alt="" className="menu-icon" />
                        <div className="text-block textovertialnavbar">
                          <h6>
                            {" "}
                            <FormattedMessage id="workersTitle" />
                          </h6>
                        </div>
                      </a>
                    </Link>
                    <Link to="/productos" style={{ textDecoration: "none" }}>
                      <a href="/productos" className="link-container-item">
                        <img src={foodIcon} alt="" className="menu-icon" />
                        <div className="text-block textovertialnavbar">
                          <h6>
                            {" "}
                            <FormattedMessage id="productsTitle" />
                          </h6>
                        </div>
                      </a>
                    </Link>
                  </div>
                </section>
              </article>
            </section>
            <section className="">
              <div className="article-container margin-boxes">
                <InformationCard prop={propExpenses}></InformationCard>
              </div>
              <div className="article-container margin-boxes">
                <InformationCard prop={propCash}></InformationCard>
              </div>
            </section>
          </section>
        </main>
      </div>
    </main>
  );
};

export default HomePage;
