import React, { Component, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import "../../../index.scss";
import "./FranchisePage.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getFranchises } from "../../../services/utilsFranchise/utilsFranchise";

import SearchBar from "../../../components/searchBar/searchBar";
import FranchiseCard from "../../../components/supervisor/FranchiseCard/FranchiseCard";
import FranchiseTypeListPage from "../../FranchiseType/FranchiseTypeListPage/FranchiseTypeListPage";
import InvoiceSchemaListPage from "../../Invoice Schemas/InvoiceSchemaListPage/InvoiceSchemaListPage";

const FranchisePage = () => {
  //Lest make the franchise fetch
  // This is info that is gonna change with the dinnaymic is only for know

  const [inputFranchise, setInputFranchise] = useState("");
  const [franchisesListDefault, setFranchisesListDefault] = useState([]);
  const [franchisesList, setFranchisesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    getFranchises().then((data) => {
      //setFranchisesList(data["franchises"]);
      setFranchisesList(data["franchises"]);
      setFranchisesListDefault(data["franchises"]);
      console.log("LIST ", franchisesList);
      console.log("LIST DEFAULT ", franchisesListDefault);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateInputFranchise = async (input) => {
    const filtered = franchisesListDefault.filter((franchise) => {
      return franchise.name.toLowerCase().includes(input.toLowerCase());
    });
    setInputFranchise(input);
    console.log(filtered);
    setFranchisesList(filtered);
  };
  return (
    <div>
      

        
        {!loading && (
          <div>
          {franchisesListDefault && (
                      <SearchBar
                        input={inputFranchise}
                        onChange={updateInputFranchise}
                        nombre="Franchise"
                      />
                    )}
                    <div className="franchises-continer">
                      {franchisesList &&
                        franchisesList.map((franchise, i) => (
                          <FranchiseCard
                            franchise={franchise}
                            key={i}
                          ></FranchiseCard>
                        ))}
                    </div>
          </div>
       )}
        {loading && (
          <div className="d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
    </div>
  );
};

export default FranchisePage;
