import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";

  const URL_FINAL = URL_DOMAIN + URL_P2[5];

  
  const getSupervisorById = async (id) => {
    return fetch(
      URL_FINAL + "/get-supervisor?fk_supervisor=" + id,
      requestOptionsGet
    ).then(getJSON);
  };

  const getSupervisorsOfCompany = async (id) => {
    return fetch(
      URL_FINAL + "/get-supervisors-of-company?fk_company=" + id,
      requestOptionsGet
    ).then(getJSON);
  };
  
  const postSupervisor = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_FINAL + "/create-supervisor", requestOptionsPost).then(
      getJSON
    );
  };
  
  const putSupervisor = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-supervisor", requestOptionsPut).then(
      getJSON
    );
  };

  const getPaginatedSupervisors = async (page, rowsPerPage, searchQuery) => {
    let url = `${URL_FINAL}/get-all-supervisors?results_per_page=${rowsPerPage}&page_number=${page+1}`
    if (searchQuery !== null){
      url += `&query_string=${searchQuery}`
    }
    return fetch(
      url,
      requestOptionsGet
    ).then(getJSON);
  };

  const postSendEmailPassword = async (formData) =>{
    requestOptionsPost.body = formData
    return fetch(URL_FINAL + "/change-password-email", requestOptionsPost).then(
      getJSON
    );
  };

  export {getSupervisorById, getSupervisorsOfCompany, postSupervisor, putSupervisor, getPaginatedSupervisors, postSendEmailPassword}