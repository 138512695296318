import React from "react";
import { Link } from "react-router-dom";
import "./FranchiseTypeCard.scss";
import FranchiseTypeDefaultImg from "../../../assets/icons/DEFAULT IMG/Franchise_type_img.jpg";
import EditIcon from "../../../assets/icons/edit-icon-24px.svg";
import DownloadIcon from "../../../assets/icons/file_download_24dp.svg";
import LinkIcon from '@material-ui/icons/Link';

const FranchiseTypeCard = (props) => {
  let franchiseType = props.franchiseType.franchise_type;
  franchiseType.name = props.franchiseType.name
  franchiseType.id = props.franchiseType.id
  const img = franchiseType.principal_image ? franchiseType.principal_image : FranchiseTypeDefaultImg;

  const goFranchiseType = ()=>{
      localStorage.setItem("fk_franchise_type", franchiseType.id)
  }

  return (
    <div className="card franchise-type">
      <img
        src={img}
        className="card-img-top franchise-type mt-2"
        alt={"img " + franchiseType.name}
      />
      <div className="card-body">
        <div className="row mt-2">
          <h5 className="card-title franchise-type">{franchiseType.name? franchiseType.name : ""}</h5>
        </div>
        <div className="row info-card-down">
            <div className="col-7"></div>
          <div className="col-5 d-flex justify-content-around">
              <Link
                className="franchise-type-card-icon"
                to={`/}`}
              >
                <img src={DownloadIcon} alt="see_icon" />
              </Link>
              <Link
                className="franchise-type-card-icon"
                to={`/`}
              >
                <LinkIcon color="primary"/>
              </Link>
              <Link
                className="franchise-type-card-icon"
                to={`/tipos-de-punto-de-venta/${franchiseType.name}/edit`}
                onClick={goFranchiseType}
              >
                <img src={EditIcon} alt="edit_icon" />
              </Link>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FranchiseTypeCard;
