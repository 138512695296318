import { Fab, LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SearchBar from "../../../components/searchBar/searchBar";
import CustomTable from "../../../components/Table/Table";
import { getPaginatedSupervisors, postSendEmailPassword } from "../../../services/utilsSupervisor/utilsSupervisor";
//Assests
import "./SupervisorListPage.scss";
import EditPrimaryIcon from "../../../assets/icons/edit-24px.svg";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from '@material-ui/icons/Email';
import EmailGreyIcon from "../../../assets/icons/mail_black_24dp.svg";
import EmailPurpleIcon from "../../../assets/icons/mail_purple_24dp.svg";
const SupervisorListPage = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [inputSupervisor, setInputSupervisor] = useState("");

  const [supervisors, setSupervisors] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);

  const productCategoryTableHeaders = [
    "usernameHeader",
    "nameLastnameHeader",
    "emailHeader",
    "companyHeader",
    "lastSessionHeader",
    "actionsHeader",
  ];

  const createSupervisorData = (
    username,
    nameLastname,
    email,
    company,
    lastSession,
    id, 
    emailSent
  ) => {
    return {
      username,
      nameLastname,
      email,
      company,
      lastSession,
      actions: (
        <>
          <Link
            to={"/"}
            onClick={(event) => sendEmail(id, event)}
          >
            <img
              src={emailSent? EmailPurpleIcon: EmailGreyIcon}
              alt="email"
              className="email-icon"
              onClick={() => console.log("id")}
            />
          </Link>
          <Link
            to={`/supervisores/${username}/edit`}
            onClick={() => goSupervisors(id)}
          >
            <img
              src={EditPrimaryIcon}
              alt="edit"
              onClick={() => console.log("id")}
            />
          </Link>
        </>
      ),
    };
  };
  const goSupervisors = (id) => {
    localStorage.setItem("fk_supervisor", id);
  };

  const sendEmail = (id, event) =>{
    event.preventDefault();
    let formDataBody = new FormData();
    formDataBody.append("fk_supervisor", id);
    postSendEmailPassword(formDataBody).then((data) =>{
      console.log("data send password", data)
      fetchData(page, rowsPerPage);
    });
    
  }

  let nameLastName = (supervisor) => {
    let name =
      supervisor.user.last_name !== undefined
        ? supervisor.user.first_name + " " + supervisor.user.last_name
        : supervisor.user.first_name;
    return name;
  };
  /**
   * Async calls
   * @param {*} page
   * @param {*} rowsPerPage
   * @param {*} searchQuery
   */
  const fetchData = async (page, rowsPerPage, searchQuery=null) => {
    setTableLoading(true);
    getPaginatedSupervisors(page, rowsPerPage, searchQuery).then((data) => {
      //let supervisorDat = data.supervisors.map((super)=>co)
      let supervisorData = data.supervisors.map((supervisor) =>
        createSupervisorData(
          supervisor.user.username,
          nameLastName(supervisor),
          supervisor.user.email,
          supervisor.company.name,
          supervisor.last_session,
          supervisor.id,
          supervisor.email_sent
        )
      );

      setSupervisors(supervisorData);

      let paginationObject = data.pagination;
      paginationObject.rowsPerPage = rowsPerPage;
      paginationObject.page = page;
      setPagination(paginationObject);
      setLoading(false);
      setTableLoading(false);
    });
  };
  const handleTextSearch = async (input) => {
    setSearchQuery(input)
    fetchData(0, rowsPerPage, input)
    setPage(0);
  };
  useEffect(() => {
    fetchData(page, rowsPerPage, searchQuery);
  }, []);

  /**
   * Handlers
   */
  const updateInputSupervisor = async (input) => {
    const filtered = supervisors.filter((supervisor) => {
      return supervisor.username.toLowerCase().includes(input.toLowerCase());
    });
    setInputSupervisor(input);
    console.log(filtered);
    setSupervisors(filtered);
  };

  const handleChangePage = async (newPage) => {
    await fetchData(newPage, rowsPerPage).then(() => {
      setPage(newPage);
    });
  };

  const handleChangeRowsPerPage = async (rowsPerPage) => {
    await fetchData(0, rowsPerPage).then(() => {
      setPage(0);
      setRowsPerPage(rowsPerPage);
    });
  };
  return (
    <div>
      <main className="section-body">
        <h1 className="section-title">
          <FormattedMessage id="supervisorsTtile" />
        </h1>
        <div>
          <Link to="supervisores/create">
            <Fab
              size="small"
              color="secondary"
              className="fab"
              aria-label="edit"
            >
              <AddIcon className="fab-icon" />
            </Fab>
          </Link>
          {/**supervisors && (
            <SearchBar
              input={inputSupervisor}
              onChange={updateInputSupervisor}
              nombre="Supervisor"
            />
          )*/}
          {loading && (
            <div className="d-flex justify-content-center spinner-container">
              <div className="spinner-border spinner-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <>
              {
               <SearchBar
                input={searchQuery}
                onChange={handleTextSearch}
                nombre="Supervisor"
              /> }
              {tableLoading && <LinearProgress />}
              <CustomTable
                headers={productCategoryTableHeaders}
                rows={supervisors}
                pagination={pagination}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default SupervisorListPage;
