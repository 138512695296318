import React from "react";
import { FormattedMessage } from "react-intl";
import { useDropzone } from "react-dropzone";
import {
  Dialog,
  Menu,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./DropzoneFile.scss";
import "../../index.scss";
import "../../globalFormStyle.scss";

import { uploadInitialInventory } from "../../services/utilsInventory/utilsInventory";
import { useState } from "react";

export default function DropzoneFile(props) {

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
    });
  const [errorsInFile, setErrrosInFile] = useState([]);

  const [seeErrors, setSeeErrors] = useState(false);

  const [seeSuccess, setSeeSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  const [openSnack, setOpenSnack] = React.useState(false);

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleCancelButton = (event) => {
    event.preventDefault();
    props.functionClose(false);
  };

  const handleSuccess = (event) =>{
    event.preventDefault();
    props.functionClose(false);
    setSeeSuccess(false);
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleCancelButtonErrors = (event) => {
    setSeeErrors(false);
    event.preventDefault();
    props.functionClose(false);
  };

  const handleTryagainButton = (event) => {
    event.preventDefault();
    setSeeErrors(false);
  };

  /**
   * TODO this is going to be handle by the parent component
   * @param event
   */

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    formDataBody.append(
      "initial_inventory",
      acceptedFiles[0],
      "initital_inventory"
    );
    formDataBody.append("fk_franchise", props.franchiseId.props);
    console.log("DROPZIBE FILE", props);
    uploadInitialInventory(formDataBody).then((data) => {
      if (data.error) {
        acceptedFiles.length = 0;
        setErrrosInFile(data.row_errors);
        setSeeErrors(true);
      } else {
        setSeeSuccess(true);
      }
      setSubmitted(false);

    });
  };

  /**
   * TODO handle by the parent
   * @param error
   * @returns {JSX.Element}
   */
  const errorsInfo = (error) => {
    let key = Object.keys(error)[0];
    let number = Object.keys(error)[0].split("_")[1];
    let averageCost = error[key].average_cost_error
      ? error[key].average_cost_error
      : "";
    let quantity = error[key].quantity_error ? error[key].quantity_error : "";

    return (
      <>
        <h6>Fila: {number}</h6>
        <p> {averageCost ? "Costo promedio: " + averageCost : ""}</p>
        <p> {quantity ? "Cantidad: " + quantity : ""}</p>
      </>
    );
  };
  return (
    <Dialog open={props.open} maxWidth={"md"} fullWidth>
      {/**
       Primera parte entrar el dropzone
       */}
      {!seeErrors && !seeSuccess && (
        <form onSubmit={handleSubmit} className="section-dialog">
          <article>
            <h2 className="section-title">
              <FormattedMessage id="importFormantTitle" />{" "}
            </h2>
          </article>
          <article className="container dropzone-style">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>
                <FormattedMessage id="dragndropDescription" />
              </p>
            </div>
            <div>
              <ul>{acceptedFileItems}</ul>
            </div>
          </article>

          <article className="buttons-inventory">
            <button
              className="btn btn-outline-primary m-2"
              onClick={handleCancelButton}
            >
              <FormattedMessage id="cancelLbl" />
            </button>

            <button className="btn btn-primary m-2" type="submit">
              {!submitted && <FormattedMessage id={"uploadInventoryLbl"} />}
              {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                        className="spinner-border spinner-primary"
                        role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
              )}
            </button>
          </article>
        </form>
      )}{" "}
      {/**
       Segunda parte mirar los errores
       */}
      <article>
        <div>
          {seeErrors && (
            <>
              <form className="section-dialog">
                <h2 className="section-title">
                  <FormattedMessage id="errorsInFormtTitle" />{" "}
                </h2>
                <br></br>
                {errorsInFile && errorsInFile.map((error) => errorsInfo(error))}
                <article className="buttons-inventory">
                  <button
                    className="btn btn-outline-primary m-2"
                    onClick={handleCancelButtonErrors}
                  >
                    <FormattedMessage id="cancelLbl" />
                  </button>

                  <button
                    className="btn btn-primary m-2"
                    onClick={handleTryagainButton}
                  >
                    <FormattedMessage id="tryagaibLbl" />
                  </button>
                </article>
              </form>
            </>
          )}
        </div>
      </article>
      {/**
       Tercera parte mirar los el susccesfull
       */}
      {seeSuccess && (
        <>
        <div>
        <article className="section-dialog flex center-items size-dialog">
            <div>
              <CheckCircleIcon color="success" />
            </div>
            <div className="success-inventory">
              <h2 className="section-title">
                ¡Inventario inicial actualizado exitosamente!
              </h2>
              <p>
                Ahora podras hacer el manejo del inventario de productos usando
                Kurúah
              </p>
            </div>
          </article>
          <form action="" className="items-right">
          <button
            className="btn btn-primary m-2"
            onClick={handleSuccess}
          >
            ACCEPTAR
          </button>
          </form>
        </div>
          
          
        </>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          <FormattedMessage id="updateInventoryAlerLbl" />
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
