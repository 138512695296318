import { render } from "@testing-library/react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import FranchiseDefaultImg from "../../../assets/icons/DEFAULT IMG/Franchise_img.jpg";
import DefaultImg from "../../../assets/img/default/punto-de-venta.jpg";
import "./FranchiseCard.scss";

const FranchiseCard = (franchise) => {
  let name = franchise["franchise"].name;
  let image = franchise["franchise"].image
    ? franchise["franchise"].image
    : DefaultImg;
  let franchiseType = franchise["franchise"]["franchise_type"].name;
  let goFranchise =() =>{
    localStorage.setItem("fk_franchise", franchise['franchise'].id);
  };
  return (
    <article className="card franchise-card">
      <Link className="" to ={`/puntos-de-venta/${name}/edit`} onClick={goFranchise}>
      <img src={image} className="card-img-top" alt="franchise_name_photo" />
      </Link>
      <div className="card-body">
        <h5 className="franchise_name_title">{name}</h5>
        <p className="card-text franchise_type_name">{franchiseType}</p>
      </div>
    </article>
  );
};

export default FranchiseCard;
