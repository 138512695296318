import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";
  
  const URL_FRANCHISES = URL_DOMAIN + URL_P2[2];
  
  const supervisorId = localStorage.getItem("fk_supervisor");
  
  /**
   * Simple categories
   * @returns
   */
  
  const getCategoriesSimple = async () => {
    return fetch(
      URL_FRANCHISES +
        "/product-categories-of-supervisor?fk_supervisor=" +
        supervisorId,
      requestOptionsGet
    ).then(getJSON);
  };
  
  export { getCategoriesSimple };
  