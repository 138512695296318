import React, { useContext, useEffect, useState } from "react";
// import OperatorDefaultImg from "../../../assets/icons/DEFAULT IMG/Operator_img.jpg";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../assets/icons/white-create-24px.svg";
import { getFranchises } from "../../services/utilsFranchise/utilsFranchise";
import { FormattedMessage } from "react-intl";
import { TextField, Select, MenuItem, Snackbar } from "@material-ui/core";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./Profile.scss";
import FranchiseItem from "../../components/supervisor/FranchiseItem/FranchiseItem";
import useUser from "../../services/usersManage/useUser";
import { changePassword, changeProfilePicture } from "../../services/usersManage/utilsUser";
import { LinearProgress } from "@material-ui/core";
import { Alert } from "@mui/material";
import Context from "../../context/UserContext";

export const Profile = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  let newProfilePicture;

  const { setSupervisorImage } = useContext(Context);

  const [openValue, setOpen] = useState(false);
  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const [franchiseList, setFranchiseList] = useState([]);

  const [imgSource, setImgSource] = useState("OperatorDefaultImg");

  const [user, setUser] = useState({
    name: "Gildardo",
    lastName: "Hernandez",
    role: "Supervisor",
    username: "marcelasanchez",
    password: "",
    new_password: "",
    repeat_new_password: "",
    role: 0
  });

  const [loading, setLoading] = useState(true);

  const [dialogLoading, setDialogLoading] = useState(false)

  const [snackbarMessage, setSnackbarMessage] = useState(<Alert severity="success">NONE</Alert>)

  const hiddenFileInput = React.useRef(null);

  const { logout } = useUser();

  const passwordsMatch = function () {
    return user.new_password === user.repeat_new_password;
  };

  const roles = (roleId)=>{
    if(roleId===1){
      return 'Supervisor'
    } else {
      return 'Superadmin'
    }
  }

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async () => {
    let parsedProfile = localStorage.profile.replace("/", "");
    console.log(localStorage, 'storage');
    parsedProfile = JSON.parse(parsedProfile)
    console.log(parsedProfile, 'parsedprofile')
    setImgSource(localStorage.profilePicture)
    const profileInformation = parsedProfile.profile.user;
    console.log(profileInformation);
    setUser({...user, 
      name: profileInformation.first_name,
      lastName: profileInformation.last_name,
      username: profileInformation.username,
      role: parsedProfile.profile.role,
    });

    setOpen(false);
    if (parsedProfile.profile.role === 2){
      setLoading(false)
    } else {
      getFranchises().then((data) => {
        console.log(data.franchises);
        setFranchiseList(data.franchises);
        setLoading(false);
      });

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClickLogOut = (e) => {
    logout();
  };

  const handleClickOpen = (event) => {
    event.preventDefault();
    setUser({...user,password: "",
    new_password: "",
    repeat_new_password: ""})
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = () => {
    if(passwordsMatch()){
      setDialogLoading(true)
      let formDataBody = new FormData();
      formDataBody.append("password", user.password)
      formDataBody.append("new_password", user.new_password)
      changePassword(formDataBody).then((data)=>{
        if(data.error){
          setDialogLoading(false)
          setSnackbarMessage(<Alert onClose={handleSnackbarClose} severity="error">
          Error: {data.error}</Alert>)
          setOpenSnackbar(true)
        } else {
          setDialogLoading(false)
          setSnackbarMessage(<Alert onClose={handleSnackbarClose} severity="success">
          Contraseña cambiada con éxito</Alert>)
          setOpenSnackbar(true)
          setOpen(false)
        }
      })
    }else {
      setSnackbarMessage(<Alert onClose={handleSnackbarClose} severity="error">
          Las contraseñas ingresadas no son iguales</Alert>)
      setOpenSnackbar(true)
    }
    console.log(passwordsMatch());
  };

  const handleEditImageClick = (event) => {
    if(user.role !== 2){
      hiddenFileInput.current.click();
    }
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      let formDataBody = new FormData();
      setUser({ ...user, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
      formDataBody.append("fk_supervisor", localStorage.fk_supervisor)
      formDataBody.append("file", newProfilePicture, "profilePicture.jpg")
      setDialogLoading(true)
      changeProfilePicture(formDataBody).then(data=>{
        console.log(data)
        if (data.error === undefined) {
          setSnackbarMessage(<Alert onClose={handleSnackbarClose} severity="success">
          Foto de perfil cambiada con éxito</Alert>)
          localStorage.setItem("profilePicture", data.image)
          setSupervisorImage(data.image)
          setOpenSnackbar(true)
          setDialogLoading(false)
        } else {
          setSnackbarMessage(<Alert onClose={handleSnackbarClose} severity="error">
          Error: vuelve a intentarlo más tarde</Alert>)
          setOpenSnackbar(true)
          setDialogLoading(false)
        }
      })
    }
  };

  const handleInputChangeInner = (event) => {
    console.log(event.target.name, event.target.value)
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <>
      <Dialog open={openValue} onClose={handleClose}>
        {dialogLoading && <LinearProgress />}
        <DialogTitle className="section-title">Cambiar contraseña</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              name="password"
              label={<FormattedMessage id="actualPasswordLbl" />}
              type="password"
              value={user.password ? user.password : ""}
              fullWidth
              variant="standard"
              onChange={handleInputChangeInner}
            />
            <TextField
              autoFocus
              margin="dense"
              id="newPassword"
              name="new_password"
              label={<FormattedMessage id="newPasswordLbl" />}
              type="password"
              value={user.new_password ? user.new_password : ""}
              fullWidth
              variant="standard"
              onChange={handleInputChangeInner}
            />
            <TextField
              autoFocus
              margin="dense"
              id="repeatNewPassword"
              name="repeat_new_password"
              label={<FormattedMessage id="repeatNewPasswordLbl" />}
              type="password"
              value={user.repeat_new_password ? user.repeat_new_password : ""}
              fullWidth
              variant="standard"
              onChange={handleInputChangeInner}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button disabled={dialogLoading} onClick={handleClose}>
            <FormattedMessage id="cancelLbl" />
          </Button>
          <Button disabled={dialogLoading} onClick={handleChangePassword}>
            <FormattedMessage id="changePasswordLbl" />
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}

                >
                 {snackbarMessage}
                </Snackbar>
      <main className="section-body profile-section-body">
      {dialogLoading && <LinearProgress />}
        <h1 className="section-title">
          <FormattedMessage id="myProfileTitle" />
        </h1>
        {!loading && (
          <form className="row g-3">
            <div className="d-flex justify-content-center mb-3">
              <img className="profile-picture" src={imgSource} alt="Avatar" />
              <div className="icon-container d-flex justify-content-center align-items-center">
                <img
                  className="icon"
                  src={EditIcon}
                  alt="edit_icon"
                  onClick={handleEditImageClick}
                />
              </div>
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleOnImageChange}
              name="file"
              hidden="hidden"
              accept="image/*"
            />
            <div className="row justify-content-center">
              <div className="user-name">
                {user.name} {user.lastName}
              </div>
              <div className="user-role">{roles(user.role)}</div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <label className="form-label">
                    <FormattedMessage id="userLbl" />
                  </label>
                  <p>{user.username}</p>
                </div>
              </div>
              <div className="col-6 password-row">
                <TextField
                  className="form-control password-field"
                  id="standard-basic"
                  disabled
                  type="password"
                  label={<FormattedMessage id="passwordLbl" />}
                  value="p0ll0z0mb13"
                  name="password"
                />
                <button
                  onClick={handleClickOpen}
                  className="btn btn-outline-primary m-2 change-password-button"
                >
                  <FormattedMessage id="changePasswordLbl" />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {franchiseList && (
                    <label className="form-label">
                      <FormattedMessage id="franchisesLbl" />
                    </label>
                  ) &&
                  franchiseList.map((franchise, i) => (
                    <p>
                      <FranchiseItem
                        key={i}
                        franchiseName={`${franchise.name} - ${franchise.city}`}
                        franchiseAddress={franchise.description}
                        franchiseImage={franchise.image}
                      />
                    </p>
                  ))}
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-end log-out-button">
                <Link onClick={handleClickLogOut} to="/">
                  <button className="btn btn-outline-primary m-2">
                    <FormattedMessage id="logOutLbl" />
                  </button>
                </Link>
              </div>
            </div>
          </form>
        )}
        {loading && (
          <div className="d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Profile;
