import React, { useEffect, useState } from "react";
import "../../../index.scss";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import {
  TextField,
  FormControlLabel,
  Switch,
  Snackbar,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  getCompanyById,
  postCompany,
  putCompany,
} from "../../../services/utilsCompany/utilsCompany";
import { Alert } from "@mui/material";

export const CompanyForm = () => {
  /**
   * Variables & Constants
   */
  const [company, setCompany] = useState({});

  const [formTitle, setFormTitle] = useState("createNewCompany");

  const [submitLabel, setSubmitLabel] = useState("createCompanyLbl");

  const [submitted, setSubmitted] = useState(false);

  const urlLocation = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  
  /**
   * Async calls
   */
  const fetchData = async () => {
    if (urlLocation.pathname.includes("edit")) {
      let companyId = localStorage.getItem("fk_company");
      setFormTitle("editCompany");
      setSubmitLabel("saveCompanyLbl");

      getCompanyById(companyId).then((data) => {
        setCompany(data.company)
      });
    }
  };
  useEffect(() => {
    fetchData().then(() => {});
  }, []);
  const handleInputChangeInner = (event) => {
    setCompany({ ...company, [event.target.name]: event.target.value });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in company) {
      if (key === "is_demo") {
        formDataBody.append(key, company[key] ? 1 : 0);
      } else {
        formDataBody.append(key, company[key]);
      }
    }
    if (urlLocation.pathname.includes("edit")) {
      let companyId = localStorage.getItem("fk_company");
      formDataBody.append("id", companyId);
      setFormTitle("editCompany");
      setSubmitLabel("saveCompanyLbl");

      putCompany(formDataBody).then((data) => {
        if(data.error === undefined){
            setSnackbarMessage(<Alert severity="success">Se ha actualizado la compañía con éxito</Alert>);
            setOpenSnackbar(true);
          } else {
            setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
            setOpenSnackbar(true);
          }
          setSubmitted(false);
      });
    } else {
      postCompany(formDataBody).then((data) => {
        console.log(data);
        if (data.error === undefined) {
          setSnackbarMessage(
            <Alert severity="success" onClose={handleClose}>
              Se ha creado la compañía con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(
            <Alert severity="error" onClose={handleClose}>Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleCheckboxChange = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };
  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      <form
        onSubmit={handleSubmit}
        className="row g-3"
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="col-6 mb-3">
          <TextField
            required
            className="form-control"
            id="standard-basic"
            label={<FormattedMessage id="nameLbl" />}
            value={company.name ? company.name : ""}
            name="name"
            onChange={handleInputChangeInner}
          />
        </div>
        <div className="mb-3 ">
          <TextField
            required
            className="form-control description-container"
            id="standard-basic"
            label={<FormattedMessage id="descriptionLbl" />}
            value={company.description ? company.description : ""}
            name="description"
            onChange={handleInputChangeInner}
            rows={4}
            multiline
          />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <TextField
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="cityLbl" />}
                value={company.city ? company.city : ""}
                name="city"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <TextField
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="countryLbl" />}
                value={company.country ? company.country : ""}
                name="country"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              {/**ZONA HORARIA */}
              <label className="form-label" htmlFor="timezoneInput">
                <FormattedMessage id="timezoneLbl" />
              </label>
              <Select
                required
                value={company.timezone !== undefined ? company.timezone : ""}
                name="timezone"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="timezoneInput"
              >
                <MenuItem value={"America/Bogota"}>America/Bogota</MenuItem>
              </Select>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              {!urlLocation.pathname.includes("edit") && <FormControlLabel
                control={
                  <Checkbox
                    checked={company.is_demo ? true : false}
                    onChange={handleCheckboxChange}
                    name="is_demo"
                    color="primary"
                  />
                }
                label={<FormattedMessage id="freedemoLbl" />}
              />}
              {/**DEMO GRATIS */}
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3"></div>
          </div>
        </div>
        <p className="sub-section-title">
          <FormattedMessage id={formTitle} />
        </p>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <TextField
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="managerAccountLbl" />}
                value={
                  company.ads_manager_account_id
                    ? company.ads_manager_account_id
                    : ""
                }
                name="ads_manager_account_id"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <TextField
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="facebookPageIdLbl" />}
                value={company.facebook_page_id ? company.facebook_page_id : ""}
                name="facebook_page_id"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <TextField
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="instagramActorIdLbl" />}
                value={
                  company.instagram_actor_id ? company.instagram_actor_id : ""
                }
                name="instagram_actor_id"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-12 d-flex justify-content-end">
            <Link className="" to={`/companias`}>
              <button className="btn btn-outline-primary m-2">
                <FormattedMessage id="cancelLbl" />
              </button>
            </Link>
            <button className="btn btn-primary m-2" type="submit">
              {!submitted && <FormattedMessage id={submitLabel} />}
              {submitted && (
                <div className="d-flex justify-content-center spinner-container">
                  <div className="spinner-border spinner-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {snackbarMessage}
      </Snackbar>
    </main>
  );
};
