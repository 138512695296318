import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Chip,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import { getFranchises } from "../../services/utilsFranchise/utilsFranchise";
import { requestOptionsGet, getJSON, URL_REPORT } from "../../services/globals";
import "./ReportPage.scss";
import { getProducts } from "../../services/utilsProduct/utilsProduct";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: 44,
    },
  },
};

const filter = createFilterOptions();

const ReportPage = () => {
  const [franchiseList, setFranchiseList] = useState([]);

  const [cashRegisterList, setCashRegisterList] = useState([]);

  const [franchiseListInput, setFranchiseListInput] = useState([]);

  const [productList, setProductList] = useState([]);

  const [productName, setProductName] = useState("");

  const [report, setReport] = useState({});

  const [reportList, setReportList] = useState([
    {
      id: 0,
      name: "Auditoría de cierre de ventas",
      url: "/get-complete-tax-report-for-franchise-and-date-range",
      description: "Obtén un consolidado de la suma de ventas en un rango de fechas, en formato pdf.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: true,
      device: true,
    },
    {
      id: 1,
      name: "Ventas detalladas por producto",
      url: "/get-detailed-sales-report-for-franchise-and-date-range",
      description: "Descarga el detalle de todos los ítems de venta y devoluciones en un rango de fechas, con los impuestos aplicados, descuentos y métodos de pago.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 2,
      name: "Consecutivos de facturas",
      url: "/get-consecutive-preffix-report-for-franchise-and-date-range",
      description: "Verifica que se hayan generado los consecutivos de factura correctamente en los distintos puntos de venta.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },

    {
      id: 4,
      name: "Historial de pedidos",
      url: "/get-inventory-transactions-in-report-for-franchise-and-date-range",
      description: "Descarga un informe del historial de reabastecimientos de tus insumos en un punto de venta, para un rango de fechas.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 5,
      name: "Histórico de inventario de producto",
      url: "/get-product-movements-report-for-franchise-and-date-range",
      description:
        "Revisa cada una de los movimientos de inventario de un único producto, en un rango de fechas y para un punto de venta.",
      productInfo: true,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 6,
      name: "Histórico de inventario general",
      url: "/get-all-product-movements-report-for-franchise-and-date-range",
      description: "Revisa cada una de los movimientos de inventario de todos, en un rango de fechas y para los puntos de venta seleccionados.",
      productInfo: false,
      multipleFranchise: true,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 7,
      name: "Histórico de inventario forzado",
      url: "/get-all-confirmations-for-franchise-and-date-range",
      description: "Descarga la información de transacciones de forzar inventario en un punto de venta, por rango de fechas.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 8,
      name: "Costo mensual",
      url: "/get-cost-average-report-for-franchise-and-date-range",
      description: "Descarga el balance de las ventas realizadas respecto a los costos de los insumos, para un rango de fechas.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 9,
      name: "Informe de existencias",
      url: "/get-product-availability-for-franchise-before-date",
      description: "Revisa las existencias de un producto, a corte de la fecha seleccionada.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: true,
      cashRegister: false,
      device: false,
    },
    {
      id: 10,
      name: "Formato de importación de transacciones",
      url: "/get-import-world-office-format",
      description: "Este formato permite generar un histórico de transacciones, que puede ser importado en algunos software contables externos.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
    {
      id: 11,
      name: "Informe de notas a crédito",
      url: "/get-credit-note-report",
      description: "Verifica los consecutivos de las notas a crédito generadas en un punto de venta, con un rango de fechas.",
      productInfo: false,
      multipleFranchise: false,
      untilDate: false,
      cashRegister: false,
      device: false,
    },
  ]);

  const [submitted, setSubmitted] = useState(false);

  const fetchData = async () => {
    getFranchises().then((data) => {
      console.log(data)
      setFranchiseList(data["franchises"]);

    });
    getProducts().then((data) => {
      let bigCategories = data["product_categories"];

      let productArray = [];
      for (let i = 0; i < bigCategories.length; i++) {
        const element = bigCategories[i]["products"];
        for (let j = 0; j < element.length; j++) {
          const element2 = element[j];
          productArray.push(element2);
        }
      }

      setProductList(productArray);
      
    });
  };

  const handleInputChangeInner = (event) => {
    setReport({ ...report, [event.target.name]: event.target.value });
    console.log({[event.target.name]: event.target.value })
    if(event.target.name ==="fk_franchise"){
      let cashRegisters = franchiseList.find((element) => element.id === event.target.value);
      if(cashRegisters.cash_register){
        setCashRegisterList(cashRegisters.cash_register);
      }else{
        setCashRegisterList([]);
      }
    }


  };

  const handleInputSelectReport = (event) => {
    let report2 = reportList.find(
      (element) => element.name === event.target.value
    );
    setReport({
      ...report,
      name: report2.name,
      description: report2.description,
      url: report2.url,
      productInfo: report2.productInfo,
      multipleFranchise: report2.multipleFranchise,
      untilDate: report2.untilDate,
      device: report2.device,
      cashRegister: report2.cashRegister
    });
  };

  const handleChangeMultiSelect = (event) => {
    setFranchiseListInput(event.target.value);
    
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  function newReport() {
    // window.location.reload();
    setReport({});
  }

  function downloadFile(link) {
    var element = document.createElement("a");
    element.setAttribute("href", link);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    setReport({});
    setSubmitted(false);

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in report) {
      formDataBody.append(key, report[key]);
      
    }

    let complementUrl = URL_REPORT + report["url"];
    if (!report.multipleFranchise && !report.untilDate && !report.productInfo && !report.device && !report.cashRegister) {
      console.log("PORQUE ENTRAS ACA")
      complementUrl =
        complementUrl +
        `?fk_franchise=${report["fk_franchise"]}&start_date=${report["start_date"]}&end_date=${report["end_date"]}`;
    }
    else if(report.cashRegister && report.device){
      complementUrl =
          complementUrl +
          `?fk_franchise=${report["fk_franchise"]}&start_date=${report["start_date"]}&end_date=${report["end_date"]}&device=WEB`;
      complementUrl = report.cashRegister ? complementUrl+ `&fk_cash_register=${report["fk_cash_register"]}`:""
    }
    else if(report.multipleFranchise && franchiseListInput){
      //Report with multiple franchise
      let multipleFran = "[";
      //let idsFranchises = "";
      for (let i = 0; i < franchiseListInput.length; i++) {
        const franchInput = franchiseListInput[i];
        
        multipleFran += franchiseList.find(element => element.name === franchInput).id + ",";
        
      }
      multipleFran = multipleFran.substring(0, multipleFran.length-1)+"]";
      complementUrl =
        complementUrl +
        `?fk_franchise_array=${multipleFran}&start_date=${report["start_date"]}&end_date=${report["end_date"]}`;
      
    }
    else if(report.untilDate){
      complementUrl =
      complementUrl +
      `?fk_franchise=${report["fk_franchise"]}&date=${report["date"]}`;
    
    }
    else if(report.productInfo){
      let productId = productName.id;
      complementUrl =
      complementUrl +
      `?fk_franchise=${report["fk_franchise"]}&start_date=${report["start_date"]}&end_date=${report["end_date"]}&fk_product=${productId}`;
  
    }
    

 getReportPetition(complementUrl).then((data) => {
      downloadFile(data.url);
    });
  };

  const getReportPetition = async (url) => {
    return fetch(url, requestOptionsGet).then(getJSON);
  };
  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id="reportsOperatives" />
      </h1>
      <form
        onSubmit={handleSubmit}
        action=""
        className="row no-padding-row"
        id="createReport"
      >
        <div className="col-6 no-padding-6">
          <div className="container-input">
            <label className="form-label" htmlFor="franchiseInput">
              <FormattedMessage id="reportToGenerate" />
            </label>
            <Select
              required
              value={report.name ? report.name : ""}
              name="name"
              onChange={handleInputSelectReport}
              className="worker-form-select no-padding-right"
              id="reportNameInput" 
            >
              {reportList &&
                reportList.map((report, i) => (
                  <MenuItem key={i} value={report.name}>
                    {report.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          {!report.multipleFranchise && (
            <div className="container-input">
              <label className="form-label" htmlFor="franchiseInput">
                <FormattedMessage id="franchiseLbl" />
              </label>
              <Select
                required
                value={report.fk_franchise ? report.fk_franchise : ""}
                name="fk_franchise"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="franchiseInput"
              >
                {franchiseList &&
                  franchiseList.map((franchise, i) => (
                    <MenuItem key={i} value={franchise.id}>
                      {franchise.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}

          {report.cashRegister && cashRegisterList.length !== 0 && (
              <div className="container-input">
                <label className="form-label" htmlFor="cashRegisterInput">
                  Caja registradora
                </label>
                <Select

                    value={report.fk_cash_register ? report.fk_cash_register : ""}
                    name="fk_cash_register"
                    onChange={handleInputChangeInner}
                    className="worker-form-select"
                    id="cashRegister"
                >
                  {cashRegisterList &&
                      cashRegisterList.map((cashRegister, i) => (
                          <MenuItem key={i} value={cashRegister.id}>
                            {cashRegister.name}
                          </MenuItem>
                      ))}
                </Select>
              </div>
          )}

          {report.multipleFranchise && (
            <div className="container-input">
              <label className="form-label" htmlFor="franchiseInput">
                <FormattedMessage id="addFranchiseLbl" />
              </label>
              <br />
              <Select
              required
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                className="worker-form-select"
                multiple
                value={franchiseListInput}
                onChange={handleChangeMultiSelect}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className="chips-container">
                    {selected.map((value) => (
                      <Chip key={value} label={value} className="chip" />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {franchiseList.map((franchise, i) => (
                  <MenuItem key={i} value={franchise.name}>
                    <Checkbox
                      checked={franchiseListInput.indexOf(franchise.name) > -1}
                    />
                    <ListItemText primary={franchise.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {report.productInfo && (
            <div className="container-input">
               <Autocomplete
                value={productName}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setProductName({
                      title: newValue,
                    });
                    
                  } else if (newValue && newValue.inputValue) {
                    setProductName({
                      title: newValue.inputValue,
                    });
                    
                  } else {
                    setProductName(newValue);
                    
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  /**
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }
 */
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={productList}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input

                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  if (option.title) {
                    return option.title;
                  }

                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={<FormattedMessage id="productLbl" />}
                  />
                )}
              />
            </div>
          )}
          {!report.untilDate && (
            <div className="container-input row">
              <label
                className="form-label no-padding-6"
                htmlFor="franchiseInput"
              >
                <FormattedMessage id="initialDateLbl" />
              </label>
              <input
              required
                type="date"
                value={report.start_date ? report.start_date : ""}
                name="start_date"
                onChange={handleInputChangeInner}
                className="worker-form-select"
              />
            </div>
          )}
          {!report.untilDate && (
            <div className="container-input row">
              <label
                className="form-label no-padding-6"
                htmlFor="franchiseInput"
              >
                <FormattedMessage id="finalDateLbl" />
              </label>
              <input
              required
                type="date"
                value={report.end_date ? report.end_date : ""}
                name="end_date"
                onChange={handleInputChangeInner}
                className="worker-form-select"
              />
            </div>
          )}
          {report.untilDate && (
            <div className="container-input row">
              <label
                className="form-label no-padding-6"
                htmlFor="franchiseInput"
              >
                <FormattedMessage id="choseeDateLbl" />
              </label>
              <input
              required
                type="date"
                value={report.date ? report.date : ""}
                name="date"
                onChange={handleInputChangeInner}
                className="worker-form-select"
              />
            </div>
          )}
          <div className="row justify-content-start">
            <div className="col-12 d-flex justify-content-start report-buttons-container">
              <button
                className="btn btn-primary report-button"
                type="submit"
                form="createReport"
                id="first-button-report"
                disabled={submitted}
              > 
                <FormattedMessage id="downloadReportLbl" />
                
              </button>

              <button
                className="btn btn-outline-primary m-2"
                onClick={(event, i) => {
                  event.preventDefault();
                  newReport();
                }}
              >
                <FormattedMessage id="newReportLbl" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-6 padding-1-rem">
          <div className="container-input">
            <label className="form-label" htmlFor="franchiseInput">
              <FormattedMessage id="reportDescriptLbl" />
            </label>
            <p>{report.description ? report.description : ""}</p>
          </div>
        </div>
      </form>
    </main>
  );
};

export default ReportPage;
