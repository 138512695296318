import React, { useEffect, useState } from "react";
import SearchBar from "../../../components/searchBar/searchBar";
import { getAllFranchiseTypesOfSupervisor } from "../../../services/utilsFranchiseType/utilsFranchiseType";
import FranchiseTypeCard from "../../../components/supervisor/FranchiseTypeCard/FranchiseTypeCard";

const FranchiseTypeListPage = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  const [franchiseTypeInput, setFranchiseTypeInput] = useState("");

  const [franchiseTypes, setFranchiseTypes] = useState([]);

  const [franchiseTypesDefaults, setFranchiseTypesDefaults] = useState([])

  const [loading, setLoading] = useState(true)

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async () => {
    getAllFranchiseTypesOfSupervisor().then((data) => {
      console.log(data.franchise_types)
      setFranchiseTypes(data.franchise_types);
      setFranchiseTypesDefaults(data.franchise_types)
      setLoading(false)
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const updateInputFranchiseType = async (input) => {
    setLoading(true)
    const filtered = franchiseTypesDefaults.filter((franchiseType) => {
      return franchiseType.name.toLowerCase().includes(input.toLowerCase());
    });
    setFranchiseTypeInput(input);
    setFranchiseTypes(filtered);
    setLoading(false)
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <>
    <SearchBar
        input={franchiseTypeInput}
        onChange={updateInputFranchiseType}
        nombre="Tipos de franquicia"
      />
      <div className="row mt-3">
      {!loading && franchiseTypes.map((franchiseType, index)=>{
        return (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <FranchiseTypeCard key={index} franchiseType={franchiseType} />
          </div>
        );
      })}
      {!franchiseTypes.length && 
        <div class="d-flex justify-content-center">
          No se encontraron tipos de franquicia con nombre "{franchiseTypeInput}"
        </div>
      }
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      </div>
      
    </>
  );
};

export default FranchiseTypeListPage;
