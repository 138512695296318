import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut
  } from "../globals"; 
  
  const URL_FINAL = URL_DOMAIN + URL_P2[2];
  
  const supervisorId = localStorage.getItem("fk_supervisor");
  
  
  const getPaginatedProductCategories = async (page, rowsPerPage, searchQuery=null) => {
    console.log(searchQuery)
    let url = `${URL_FINAL}/paginated-product-categories-of-supervisor?fk_supervisor=${supervisorId}&results_per_page=${rowsPerPage}&page_number=${page+1}`
    if (searchQuery !== null){
      url += `&query_string=${searchQuery}`
    }
    return fetch(
      url,
      requestOptionsGet
    ).then(getJSON);
  };

  const getProductCategory = async (id) => {
    return fetch(
      `${URL_FINAL}/get-a-product-category?fk_product_category=${id}`,
      requestOptionsGet
    ).then(getJSON);
  }

  const postProductCategory = async (formData) => {
    requestOptionsPost.body = formData;
    console.log(formData.values)
    return fetch(URL_FINAL + "/create-new-product-category", requestOptionsPost).then(
      getJSON
    );
  };
  
  const putProductCategory = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-a-product-category", requestOptionsPut).then(
      getJSON
    );
  };
  
  
  
  export {getPaginatedProductCategories, getProductCategory, postProductCategory, putProductCategory}