import React, { Component, useContext } from "react";
import "./NavBar.scss";
import { Link } from "react-router-dom";
import Logo from "../../../assets/icons/kuruah-logo.svg";
import foodHouseicon from "../../../assets/icons/food_house-24px.svg";
import groupIcon from "../../../assets/icons/group-24px.svg";
import foodIcon from "../../../assets/icons/fastfood-24px.svg";
import cashIcon from "../../../assets/icons/local_atm_black_24dp.svg"
import { FormattedMessage } from "react-intl";
import useUser from "../../../services/usersManage/useUser";
import Context from "../../../context/UserContext";

export const Navbar = () => {
  const {isLogged, logout }  = useUser();
  const { userRole } = useContext(Context);
  const handleClickLogOut = e =>{
    logout();
  }
  console.log("This is user", userRole)
  return (
    <div 
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      className="vertical_div_menu col-2 navbar"
      id="wrapper" 
    >
      <div className="menu_container">
        <Link to="/home">
          <p aria-current="page" className="brand">
            <img src={Logo} alt="logo_img" className="img_logo" />
          </p>
        </Link>
        {userRole === "1" &&(
        <div>
        <div className="gestion">
          <h5 className="titulo-sec-nav-bar">
            <FormattedMessage className="navbar-option" id="managementTitle" />
          </h5>
          <Link to="/puntos-de-venta" style={{ textDecoration: "none" }}>
            <a href="/puntos-de-venta" className="link-nav-item">
              <img src={foodHouseicon} alt="" className="menu-icon" />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="franchiseTitle" />
              </div>
            </a>
          </Link>
          <Link to="/trabajadores" style={{ textDecoration: "none" }}>
            <a href="/trabajadores" className="link-nav-item">
              <img src={groupIcon} alt="" className="menu-icon" />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="workersTitle" />
              </div>
            </a>
          </Link>
          <Link to="/productos" style={{ textDecoration: "none" }}>
            <a href="/productos" className="link-nav-item">
              <img src={foodIcon} alt="" className="menu-icon" />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="productsTitle" />
              </div>
            </a>
          </Link>
          
          <Link to="/inventarios" style={{ textDecoration: "none" }}>
            <a href="/inventarios" className="link-nav-item">
              <img
                src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98b4f133b9c87c6870_local_offer-24px.svg"
                alt=""
                className="menu-icon"
              />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="inventoryTitle" />
              </div>
            </a> 
          </Link>
          <Link to="/efectivo" style={{ textDecoration: "none" }}>
            <a href="/efectivo" className="link-nav-item">
              <img
                src={cashIcon}
                alt=""
                className="menu-icon"
              />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="cashTitle" />
              </div>
            </a>
          </Link>
        </div>
        <div className="pagos">
          <h6 className="titulo-sec-nav-bar">
            <FormattedMessage className="navbar-option" id="consolidatedTitle" /> 
          </h6>
          <a href="/informes" className="link-nav-item">
            <img
              src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98a317b72a3e03c8bd_payment-24px.svg"
              alt=""
              className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <FormattedMessage className="navbar-option" id="operationalReportsTitle" />
            </div>
          </a>
          <a href="/ventas" className="link-nav-item">
            <img
                src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98a317b72a3e03c8bd_payment-24px.svg"
                alt=""
                className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <FormattedMessage className="navbar-option" id="salesBalanceTitle" />
            </div>
          </a>
          <a href="/historial-de-pago" className="link-nav-item">
            <img
              src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd989ec9f471e9b28b97_receipt-24px.svg"
              alt=""
              className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <FormattedMessage className="navbar-option" id="usageIndicatiorsTitle" />
            </div>
          </a>

          <a href="/stock" className="link-nav-item">
            <img
                src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd989ec9f471e9b28b97_receipt-24px.svg"
                alt=""
                className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <FormattedMessage className="navbar-option" id="stockTitle" />
            </div>
          </a>
        </div></div>)}
        
        { userRole === "2" &&(<div className="gestion">
          <h5 className="titulo-sec-nav-bar">
            <FormattedMessage className="navbar-option" id="managementTitle" />
          </h5> 
          <Link to="/companias" style={{ textDecoration: "none" }}>
            <a href="/companias" className="link-nav-item">
              <img src={foodHouseicon} alt="" className="menu-icon" />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="companiesTitle" />
              </div>
            </a>
          </Link>
          <Link to="/supervisores" style={{ textDecoration: "none" }}>
            <a href="/supervisores" className="link-nav-item">
              <img src={groupIcon} alt="" className="menu-icon" />
              <div className="text-block textovertialnavbar">
                <FormattedMessage className="navbar-option" id="Supervisor" />
              </div>
            </a>
          </Link>
          
          
        </div>)}        
        <div id="logout-div">
          <a href="/portal-de-ayuda" target="_blank" className="link-nav-item">
            <img
              src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5fa96fde87a4b067958b510c_help_outline-white-18dp.svg"
              alt=""
              className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <FormattedMessage className="navbar-option" id="helpTitle" />
            </div>
          </a>
          <a
            id="logoutBtn"
            href=" "
            className="link-nav-item"
          >
            <img
              src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5efcd3ed8bd97b2bdd208f4b_exit_to_app-white-18dp.svg"
              alt=""
              className="menu-icon"
            />
            <div className="text-block textovertialnavbar">
              <Link onClick={handleClickLogOut} className="vertical-link">
              <FormattedMessage className="navbar-option" id="logOutTitle" />
              </Link>
            </div>
          </a>
        </div>

        <div className="titulo-sec-nav-bar">v 1.3.0</div>
      </div>
    </div>
  );
};

export default Navbar;