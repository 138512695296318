import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import "./navBarProfile.scss";
import Context from "../../../context/UserContext";
import ProfileImg from "../../../assets/icons/ICONS-LOGO.jpeg";

export const NavBarProfile = () => {
  const { supervisorProfile, supervisorImage } = useContext(Context);
  //let supervisorName = "Kurúah";
  const [supervisorName, setSupervisorName] = useState("Kurúah");
  const [supervisorImg, setSupervisorImg] = useState(ProfileImg);
  useEffect(()=>{

    if(supervisorProfile.profile.user.last_name){
      setSupervisorName(supervisorProfile.profile.user.first_name +" "+supervisorProfile.profile.user.last_name);
    }
    else{
      setSupervisorName(supervisorProfile.profile.user.first_name);
    }
    if(supervisorImage){
      setSupervisorImg(supervisorImage);
    }
    
  }, [supervisorProfile, supervisorImage])
  
  

  /**
  const nameProfile = useCallback(() =>{
    console.log("THIS IS name",supervisorName)
  },[supervisorName]);
  nameProfile();
  
  useEffect(()=>{
    console.log(supervisorProfile.profile.user.first_name)
  }, [supervisorProfile])
  */
  return (
    <nav className="navbar" id="navBarPorfile">
      <div className="container-fluid" id="nav-noflex">
        <span className="navbar-brand mb-0 h1">
          <div className="d-flex flex-row-reverse">
          
          <p className="text-end profile-name"><Link to={`/perfil/${supervisorName}`}>{supervisorName}</Link></p>
          <Link to={`/perfil/${supervisorName}`}>
              <img
                src={supervisorImg}
                alt="user_img"
                className="profile-img"
              />
          </Link>

          </div>
        </span>
      </div>
    </nav>
  );
};
