import React, { useState, useEffect } from "react";
import "../../../index.scss";
import "./ProductForm.scss";
import ProductDefaultImg from "../../../assets/icons/DEFAULT IMG/Product_img.jpg";
import {
  postProduct,
  getProductById,
  putProduct,
} from "../../../services/utilsProduct/utilsProduct";
import { getCategoriesSimple } from "../../../services/utilsCategories/utilsCategories";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";

import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import { FormattedMessage } from "react-intl";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ProductIngredientPage from "../ProductIngredientPage/ProductIngredientPage";
import { Alert } from "@material-ui/lab";

//This is for mobile
import { styled, Box } from "@mui/system";

import Modal from "@mui/material/Modal";

const filter = createFilterOptions();

const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "grid",
  placeItems: "center",
};

const ProductForm = () => {
  /**
   * Variables & Constans
   */

  const [product, setProduct] = useState({
    inventory_available: false,
    customer_visible: false,
    quantity_mode: false,
    iva_mode: false, 
    ipo_mode: false
  });

  const [imgSource, setImgSource] = useState(ProductDefaultImg);

  const [loading, setLoading] = useState(true);

  const [formTitle, setFormTitle] = useState("createNewProduct");

  const [submitLabel, setSubmitLabel] = useState("createProductLbl");

  const [inEditSize, setInEditSize] = useState("section-body col");

  const [submitted, setSubmitted] = useState(false);

  const [productCategory, setProductCategory] = useState("");

  const [productCategoriesList, setProductCategoriesList] = useState([]);

  const [franchiseTypeList, setFranchiseTypeList] = useState([]);

  const [sellPrice, setSellPrice] = React.useState();

  const [disabledSelect, setDisableSelect] = useState(true);

  const [ingredientsOfProduct, setIngredientsOfProduct] = useState([]);

  const hiddenFileInput = React.useRef(null);

  const urlLocation = useLocation();

  let newProfilePicture;

  /**
   * Async calls
   */

  const fetchData = async () => {
    //Product manage in edit
    if (urlLocation.pathname.includes("edit")) {
      let productId = localStorage.getItem("fk_product");
      let fk_franchise_type;
      setFormTitle("editProduct");
      setSubmitLabel("saveProductLbl");
      setInEditSize("section-body col-7");

      getProductById(productId).then((data) => {
        let productData = data.product;
        setSellPrice(productData.sell_price);
        setIngredientsOfProduct(productData.ingredients);
        //Manejo de las categorias
        console.log("This is ", productData);
        fk_franchise_type = productData.product_category.franchise_type.id;
        setProduct({
          ...product,
          name: productData.name,
          fk_franchise_type: productData.product_category.franchise_type.id,
          description: productData.description,
          customer_visible: productData.customer_visible,
          inventory_available: productData.inventory_available,
          fk_product_category: productData.product_category.id,
          sell_price: productData.sell_price,
          quantity_mode: productData.quantity_mode,
          barcode: productData.barcode,
          ipo: productData.ipo,
          iva: productData.iva,
          iva_mode: productData.iva_mode,
          ipo_mode: ipoHandler(productData.ipo_mode),
          internal_code: productData.internal_code
        });
        productData.image
          ? setImgSource(productData.image)
          : setImgSource(imgSource);
        setFranchiseTypeList([productData.product_category.franchise_type]);
      });

      getCategoriesSimple().then((data) => {
        let categoriesList = data["product_categories"];
        let finalCategorieList = categoriesList.filter(function (cat) {
          return cat.franchise_type.id === fk_franchise_type;
        });
        setProductCategoriesList(finalCategorieList);
      });

      setLoading(false);
    } else {
      getCategoriesSimple().then((data) => {
        setProductCategoriesList(data["product_categories"]);
        setLoading(false);
      });

      getFranchiseTypes().then((data) => {
        setFranchiseTypeList(data["franchise_types"]);
      });
    }
  };
  let ipoHandler = (ipo) => {
    if (ipo === 2) return 1;
    else return ipo;
  };
  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  /**
   * Input Handlers
   * @param {*} event
   */
  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setProduct({ ...product, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleChangeCheckBox = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.checked });
  };

  const handleChangeOfCategory = (value) => {
    //SI ya existia la categoria
    if (value) {
      if (value.franchise_type) {
        let categoryId = value.id;
        let franchiseTypeId = value.franchise_type.id;
        setProduct({
          ...product,
          fk_product_category: categoryId,
          fk_franchise_type: franchiseTypeId,
        });
      } else {
        //setProduct({ ...product, product_category_name: value.inputValue });
        setDisableSelect(false);
      }
    } else {
      setProduct({ ...product, fk_franchise_type: "" });
    }

    //SI ES UNA NUEVA CATEGORIA
  };

  const handleInputChangeInner = (event) => {
    console.log({[event.target.name]: event.target.value});
    setProduct({ ...product, [event.target.name]: event.target.value });
    console.log(product);

  };

  const [open, setOpen] = React.useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setCloseTime = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    /**
     * Vamos a hacer el manejo de las categorias
     * 1. Cuando es una categoria antigua
     * 2. Cuando es una categoria nueva -> toca enviar el nombre de la categoria y el tipo de franquicia
     */

    for (const key in product) {
      if (key === "file") {
        formDataBody.append(key, product[key], "productPicture.jpg");
      } else if (
        key === "inventory_available" ||
        key === "customer_visible" ||
        key === "iva_mode" ||
        key === "ipo_mode" || key ==="quantity_mode"
      ) {
        
        formDataBody.append(key, product[key] ? 1 : 0);
      } else if (
        key === "fk_franchise_type" ||
        key === "product_category_name" ||
        key === "fk_product_category"
      ) {
        continue;
      }
       else if(key ==="ipo"){
        if((product["ipo_mode"] === 1 ) && (product["ipo"] ==="" || product["ipo"] ===undefined ||product["ipo"] ===0 )){
          formDataBody.append("ipo", 0.08);
        }else{
          formDataBody.append(key, product[key]);
        }
      }else if(key ==="sell_price"){
        let sellPrice = product[key];
        console.log(sellPrice, typeof sellPrice, "This is sall price")
        let finalPrice = typeof sellPrice === "string" ? sellPrice.replaceAll(",", ""): sellPrice;
        formDataBody.append(key, finalPrice);
      }
      else {
        console.log(key, product["barcode"]);
        formDataBody.append(key, product[key]);
      }
    }

    if (urlLocation.pathname.includes("edit")) {
      formDataBody.append(
        "fk_product_category",
        product["fk_product_category"]
      );
      formDataBody.append("fk_product", localStorage.getItem("fk_product"));

      for (var pair of formDataBody.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      putProduct(formDataBody).then((data) => {
        setSubmitted(false);
        //Aca vamos a poner el tocast!
        setOpen(true);
        //setTimeout(setCloseTime, 1000);
      });
    } else {
      if (!productCategory.franchise_type) {
        formDataBody.append("fk_franchise_type", product["fk_franchise_type"]);
        formDataBody.append("product_category_name", productCategory.title);
      } else {
        formDataBody.append("fk_product_category", productCategory.id);
      }
      postProduct(formDataBody).then((data) => {
        if (!data.error) {
          localStorage.setItem("fk_product", data.product.id);
          setSubmitted(false);
          setOpenModal(true);
        }
      });
    }
  };

  /**
   * Render elements
   */
  return (
    <div className="row" id="container-product-form">
      <main className={inEditSize} id="product-info">
        <h1 className="section-title">
          <FormattedMessage id={formTitle} />
        </h1>
        <article>
          {!loading && (
            <form onSubmit={handleSubmit} className="row g-3">
              <div className="d-flex justify-content-center mb-4">
                <img className="profile-picture" src={imgSource} alt="Avatar" />
                <div className="icon-container d-flex justify-content-center align-items-center">
                  <img
                    className="icon"
                    src={EditIcon}
                    alt="edit_icon"
                    onClick={handleEditImageClick}
                  />
                </div>
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleOnImageChange}
                name="file"
                hidden="hidden"
                accept="image/*"
              />
              <div className="col-6 mb-3">
                <TextField
                  required
                  className="form-control"
                  label={<FormattedMessage id="nameLbl" />}
                  value={product.name ? product.name : ""}
                  name="name"
                  onChange={handleInputChangeInner}
                />
              </div>
              <div className="mb-3">
                <TextField
                  className="form-control"
                  label={<FormattedMessage id="descriptionLbl" />}
                  rows={4}
                  multiline
                  value={product.description ? product.description : ""}
                  name="description"
                  onChange={handleInputChangeInner}
                  required
                />
              </div>
              <div className="row">
                {!urlLocation.pathname.includes("edit") && (
                  <div className="col-6 mb-3 adjust-margin-cat">
                    <Autocomplete
                      value={productCategory}
                      onChange={(event, newValue) => {
                        handleChangeOfCategory(newValue);
                        if (typeof newValue == "string") {
                          setProductCategory({
                            title: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          setProductCategory({
                            title: newValue.inputValue,
                          });
                        } else {
                          setProductCategory(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="category-input"
                      options={productCategoriesList}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }

                        if (option.title) {
                          return option.title;
                        }

                        // Regular option
                        return option.name;
                      }}
                      renderOption={(option) => (
                        <React.Fragment>
                          <div>
                            {option.name}
                            <br />
                            {option["franchise_type"]
                              ? option["franchise_type"].name
                              : ""}
                          </div>
                        </React.Fragment>
                      )}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={<FormattedMessage id="categoryLbl" />}
                        />
                      )}
                    />
                  </div>
                )}

                {urlLocation.pathname.includes("edit") && (
                  <div className="col-6 mb-3">
                    <label className="form-label" htmlFor="franchiseInput">
                      <FormattedMessage id="categoryLbl" />
                    </label>
                    <Select
                      required
                      value={
                        product.fk_product_category
                          ? product.fk_product_category
                          : ""
                      }
                      onChange={handleInputChangeInner}
                      name="fk_product_category"
                      className="worker-form-select"
                      id="categoryInput"
                    >
                      {productCategoriesList &&
                        productCategoriesList.map((category, i) => (
                          <MenuItem key={i} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                )}

                <div className="col-6 mb-3">
                  <label className="form-label" htmlFor="franchiseInput">
                    <FormattedMessage id="franchiseTypeLbl" />
                  </label>
                  <Select
                    required
                    value={
                      product.fk_franchise_type ? product.fk_franchise_type : ""
                    }
                    onChange={handleInputChangeInner}
                    name="fk_franchise_type"
                    className="worker-form-select"
                    id="franchiseTypeInput"
                    disabled={disabledSelect}
                  >
                    {franchiseTypeList &&
                      franchiseTypeList.map((franchise, i) => (
                        <MenuItem key={i} value={franchise.id}>
                          {franchise.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <CurrencyTextField
                    label={<FormattedMessage id="sellPriceLbl" />}
                    variant="standard"
                    className="form-control"
                    value={sellPrice}
                    name="sell_price"
                    currencySymbol="$"
                    outputFormat="number"
                    onChange={handleInputChangeInner}
                    required
                  />
                  <br />
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.inventory_available
                            ? Boolean(product.inventory_available)
                            : false
                        }
                        onChange={handleChangeCheckBox}
                        name="inventory_available"
                      />
                    }
                    label={<FormattedMessage id="inventoryControlLbl" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.customer_visible
                            ? Boolean(product.customer_visible)
                            : false
                        }
                        onChange={handleChangeCheckBox}
                        name="customer_visible"
                      />
                    }
                    label={<FormattedMessage id="customerVisibleLbl" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.quantity_mode
                            ? Boolean(product.quantity_mode)
                            : false
                        }
                        onChange={handleChangeCheckBox}
                        name="quantity_mode"
                      />
                    }
                    label={<FormattedMessage id="quantityModeLbl" />}
                  />
                </div>
                <div className="col-6 mb-3">
                  <TextField
                    onChange={handleInputChangeInner}
                    className="form-control"
                    value={product.barcode ? product.barcode : ""}
                    name="barcode"
                    label={<FormattedMessage id="barCodeLbl" />}
                  />
                  <br />
                  <br />

                  <TextField
                    
                    className="form-control"
                    label={<FormattedMessage id="internalCodeLbl" />}
                    value={product.internal_code ? product.internal_code : ""}
                    name="internal_code"
                    onChange={handleInputChangeInner}
                  />
                </div>
              </div>
              <p className="section-title">Información tributaria</p>
              <div className="row">
                <div className="col-6 mb-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.iva_mode ? Boolean(product.iva_mode) : false
                        }
                        onChange={handleChangeCheckBox}
                        name="iva_mode"
                      />
                    }
                    label={<FormattedMessage id="ivaModeLbl" />}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          product.ipo_mode ? Boolean(product.ipo_mode) : false
                        }
                        onChange={handleChangeCheckBox}
                        name="ipo_mode"
                      />
                    }
                    label={<FormattedMessage id="ipoModeLbl" />}
                  />
                  <br />

                  <label className="form-label" htmlFor="franchiseInput">
                    <FormattedMessage id="ivaPercentageLbl" />
                  </label>
                  <Select
                    
                    value={product.iva ? product.iva : ""}
                    onChange={handleInputChangeInner}
                    name="iva"
                    className="worker-form-select"
                    id="categoryInput"
                  >
                    <MenuItem value={0}>0%</MenuItem>

                    <MenuItem value={0.05}>5%</MenuItem>
                    <MenuItem value={0.08}>8%</MenuItem>
                    <MenuItem value={0.19}>19%</MenuItem>
                  </Select>
                </div>
                <div className="col-6 mb-3">
                  <TextField
                    className="form-control"
                    label={<FormattedMessage id="fixIpoLbl" />}
                    value={product.ipo ? product.ipo : ""}
                    name="ipo"
                    onChange={handleInputChangeInner}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end">
                  <Link className="" to={`/productos`}>
                    <button className="btn btn-outline-primary m-2">
                      <FormattedMessage id="cancelLbl" />
                    </button>
                  </Link>
                  <button
                    className="btn btn-primary m-2"
                    type="submit"
                    disabled={submitted}
                  >
                    {!submitted && <FormattedMessage id={submitLabel} />}
                    {submitted && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div
                          className="spinner-border spinner-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                  {/* </Link> */}
                </div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert onClose={handleClose} severity="success">
                    <FormattedMessage id="uptadedProductAlertLbl" />
                  </Alert>
                </Snackbar>
                {/**Let's create the modal */}
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-box">
                      <h2 className="modal-title">
                        <FormattedMessage id="createdProductLbl" />
                      </h2>
                      <p className="modal-question">
                        <FormattedMessage id="whatNowLbl" />
                      </p>
                      <div className="text-center">
                        <Link
                          className="modal-option"
                          to={`/productos/create`}
                          onClick={() => window.location.reload(false)}
                        >
                          <FormattedMessage id="createOtherProductLbl" />
                        </Link>

                        <Link
                          className="modal-option"
                          to={`/productos/newProduct/edit`}
                        >
                          <FormattedMessage id="setUpThisProduct" />
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </form>
          )}
        </article>
      </main>
      {urlLocation.pathname.includes("edit") && (
        <aside className="section-body col" id="ingredient-info">
          <h2 className="section-title">
            <FormattedMessage id="compositionTitle" />
          </h2>
          <article>
            <ProductIngredientPage
              ingredients={ingredientsOfProduct}
            ></ProductIngredientPage>
          </article>
        </aside>
      )}
    </div>
  );
};

export default ProductForm;
