import { getAllProductsByProvider } from "../../services/utilsProvider/utilsProvider";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import "./StockPage.scss";
import LinearProgress from "@mui/material/LinearProgress";
import StockForm from "./StockForm";

const RowProductInfo = ({ product }) => {
  const [openFullInfo, setOpenFullInfo] = useState(false);

  return (
    <>
      {!openFullInfo && (
        <div
          className="row row-product-info row-product-simple"
          onClick={() => setOpenFullInfo(!openFullInfo)}
        >
          <div className="col center-text-column product-name-justify ">
            <p>{product.name}</p>
          </div>
          <div className="col-2 center-text-column">
            <p>{product.inStock}</p>
          </div>
          <div className="col-2 center-text-column">
            <p>{product.sales}</p>
          </div>
          <div className="col-2 center-text-column">
            <p>{Math.round(product.daySales)}</p>
          </div>
          <div className="col-2 center-text-column">{product.suggested}</div>
        </div>
      )}
      {openFullInfo && (
        <div className="full-info-container">
          <div
            className="row-product-info principal-full-info items-in-corners"
            onClick={() => setOpenFullInfo(!openFullInfo)}
          >
            <h5>{product.name}</h5>
            <h5>SKU: {product.sku}</h5>
            {console.log(product)}
          </div>
          <div className="row-product-info row">
            <div className="col box-info-stock">
              <p>En tienda</p>
              <h6>{product.inStock}</h6>
            </div>
            <div className="col box-info-stock">
              <p>Ventas mes</p>
              <h6>{product.sales}</h6>
            </div>
            <div className="col box-info-stock">
              <p>Ventas dia</p>
              <h6>{Math.round(product.daySales)}</h6>
            </div>

            <div className="col box-info-stock">
              <p>Dias de Inv.</p>
              <h6>{Math.floor(product.stockDays)}</h6>
            </div>
            <div className="col box-info-stock">
              <p>Politica DI</p>
              <h6>{product.sales}</h6>
            </div>
            <div className="col box-info-stock">
              <p>Sugerido</p>
              <h6>{product.suggested}</h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const StockProviderDetail = ({
  provider,
  franchiseSelected,
  setFranchiseSelected,
    pageState, critialProducts
}) => {
  const [productsListData, setProductsListData] = useState([]);

  const [loadingProducts, setLoagindProducts] = useState(false);

  const [defautlProductsListData, setDefaultProducts] = useState([]);

  const [unTransformedData, setUnTransformedData] = useState([]);
  /**
   * Search Bar
   */
  const [searchBarValue, setSearchBarValue] = useState("");

  /**
   * Dates to look
   */
   const [dates, setDates] = useState({
    date_initial: "",
    date_final: "",
  });



  const fetchData = () => {
    if(pageState ===1){
      let initialDate = dates.date_initial;
      let finalDate = dates.date_final;
      if (initialDate !== "" && finalDate !== "" && provider) {
        if (initialDate <= finalDate) {
          setLoagindProducts(true);

          getAllProductsByProvider(provider.id, initialDate, finalDate).then(
              (data) => {
                //setProductsListData(data);
                let transformedData = data.map((product) =>
                    transformProductsFranchiseData(product)
                );
                setProductsListData(transformedData);

                setDefaultProducts(transformedData);
                setUnTransformedData(data);

                setLoagindProducts(false);
              }
          );
        }
      }
    }else{
      // if(provider){
      //   let data = provider.products[0];
      //   //setProductsListData(data);
      //   let transformedData = data.map((product) =>
      //       transformProductsFranchiseData(product)
      //   );
      //   setProductsListData(transformedData);
      //
      //   setDefaultProducts(transformedData);
      //   setUnTransformedData(data);
      // }

      if(provider.id !== 0){
        let data = provider.products[0];
        //setProductsListData(data);
        let transformedData = data.map((product) =>
            transformProductsFranchiseData(product)
        );
        setProductsListData(transformedData);

        setDefaultProducts(transformedData);
        setUnTransformedData(data);
        console.log("Este es el provider   ",provider.products[0])
      }


    }
  };

  const transformProductsFranchiseData = (product) => {
    //Product analisys
    let totalInStock = 0;
    let totalSales = 0;
    let totalSalesDay = 0;

    let totalSuggested = 0;
    let totalStockDays = 0;
    let franchises = Object.keys(product);

    if (franchiseSelected.id === 0) {
      for (let i = 0; i < franchises.length; i++) {
        let franchisesKey = franchises[i];
        if (franchisesKey === "name" || franchisesKey === "sku") {
          //console.log("ENTRO ACA");
        } else {
          let productFranchiseInfo = product[franchisesKey];
          // console.log(productFranchiseInfo, product, productFranchiseInfo.in_stock, productFranchiseInfo.sales_dates)
          totalInStock += productFranchiseInfo.in_stock;
          totalSales += productFranchiseInfo.sales_dates;
          totalSalesDay += productFranchiseInfo.sales_day;
          totalStockDays += productFranchiseInfo.stock_days;
          totalSuggested += productFranchiseInfo.suggested_order;
        }
      }
    } else {
      console.log("NOMBRE DEL ASUNTO" ,franchiseSelected.name, product)
      let franchiseInfo = product[franchiseSelected.name];
      totalInStock = franchiseInfo.in_stock;
      totalSales = franchiseInfo.sales_dates;
      totalSalesDay = franchiseInfo.sales_day;
      totalStockDays = franchiseInfo.stock_days;
      totalSuggested = franchiseInfo.suggested_order;
    }
    return {
      name: product.name,
      sku: product.sku,
      inStock: totalInStock,
      sales: totalSales,
      daySales: totalSalesDay,
      stockDays: totalStockDays,
      suggested: Math.floor(totalSuggested),
    };
  };

  const filteredProducts = (input) => {
    let filtered = defautlProductsListData.filter((product) => {
      return product.name.toLowerCase().includes(input.toLowerCase());
    });
    setProductsListData(filtered);
  };

  const searchProductChange = (input) => {
    filteredProducts(input);
    setSearchBarValue(input);
  };


  useEffect(() => {
    fetchData();
  }, [provider,dates]);

  useEffect(() => {
    let transformedData = unTransformedData.map((product) =>
      transformProductsFranchiseData(product)
    );
    console.log("Productos transformado s", unTransformedData)
    setDefaultProducts(transformedData);
    setProductsListData(transformedData);
    
  }, [franchiseSelected]);


  return (
    <>

      <div className="products-provider-container">
        {provider.id !== 0 && <StockForm
            setFranchiseInformation={setFranchiseSelected}
            searchValue={searchBarValue}
            onChangeSearch={searchProductChange}
            dates={dates}
            setDates={setDates}
            pageStateCritial={pageState}
            providerName={provider.name}
        />}
        {provider.id !==0 && <div className="row row-product-simple">
          <div className="col center-text-column product-name-justify">
            <p>Producto</p>
          </div>
          <div className="col-2 center-text-column">
            <p>En tienda</p>
          </div>
          <div className="col-2 center-text-column">
            <p>Ventas</p>
          </div>
          <div className="col-2 center-text-column">
            <p>Ventas / dia</p>
          </div>
          <div className="col-2 center-text-column">
            <p>Sugerido</p>
          </div>
        </div>}

        {(provider.id !==0 && loadingProducts) && <LinearProgress color="secondary" />}

        {productsListData.map((product, index) => {
          return <RowProductInfo product={product} key={index} />;
        })}
      </div>
    </>
  );
};

export default StockProviderDetail;
