import StockForm from "./StockForm";
import "./StockPage.scss";
import { useEffect, useState } from "react";
import {getAllProvidersBySupervisor, getCritialProvidersBySupervisor} from "../../services/utilsProvider/utilsProvider";
import ProvidersCard from "../../components/supervisor/ProvidersCard/ProvidersCard";
import StockProviderDetail from "./StockProviderDetail";
import { FormControl, Input, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchBar from "../../components/searchBar/searchBar";

import LoadingGif from "../../assets/img/loaderGif.gif";

const StockPage = ({state}) => {
  const [providersListData, setProviderslistData] = useState([]);

  const [franchiseSelected, setFranchiseSelected] = useState({
    name: "",
    id: 0,
  });

  const [activeProvider, setActiveProvider] = useState({
    id:0
  });

  const [searchProviderValue, setSearchProvider] = useState("");

  const [loadingInitial , setLoadingInitial] = useState(true);

  const fetchData = () => {
    let fk_supervisor = localStorage.getItem("fk_supervisor");

    setLoadingInitial(true);

    if(state === 1){
      getAllProvidersBySupervisor(fk_supervisor).then((data) => {
        setProviderslistData(data.providers);
        searchProvider("");
        setLoadingInitial(false);
      });
    }else if(state ===2){

      getCritialProvidersBySupervisor(fk_supervisor).then((data)=>{
        console.log("This is the data", data);
        setProviderslistData(data);
        searchProvider("");
        setLoadingInitial(false);
       // setCriticalProductsProvider(data.products[0])
      });
    }

  };


  const searchProvider =  (input) =>{
    let filtered = providersListData.filter((provider) =>{
        return provider.name.toLowerCase().includes(input.toLowerCase());
    });
    return filtered;
  };

  const searchProviderChange = (input)=>{
    setSearchProvider(input);
  }
  useEffect(() => {
    fetchData();
    
  }, []);


  return (
    <div className="row">
      { !loadingInitial && <div className="col-4 lineborder-right providers-container">
        <div className="cards-container">
          {state === 1 && <div className="form-container">
            {/* <FormControl variant="standard" sx={{ m: 0, minWidth: 290 }}>
              <TextField
                label="Buscar proveedor"
                variant="standard"
                color="secondary"
                onChange={searchProviderChange}
              />
            </FormControl> */}
            {providersListData && <SearchBar
                input={searchProviderValue}
                onChange={searchProviderChange}
                nombre="Proveedor"/>}
          </div>}

          {searchProvider(searchProviderValue).map((provider, indexProv) => (
              <ProvidersCard
                  provider={provider}
                  key={indexProv}
                  activeProvider={activeProvider}
                  setActiveProvider={setActiveProvider}
              />
          ))}
        </div>
      </div>
      }

      {!loadingInitial &&

        <div className="col providers-container">

        <StockProviderDetail
            provider={activeProvider}
            franchiseSelected={franchiseSelected}
            setFranchiseSelected={setFranchiseSelected}
            pageState={state}

        />
      </div>}
      {loadingInitial && <div className={"center-items"}>
          <p>
            Analizando proveedores..
          </p>
        <img src={LoadingGif} alt="" className={"loader-img"}/>

      </div>}
    </div>
  );
};

export default StockPage;
