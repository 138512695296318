import React, { useState } from "react";
import "./RectangularImagePicker.scss";
import { FormattedMessage } from "react-intl";

export default function RectangularImagePicker(props) {
    const keyName = props.keyName

  const hiddenFileInput = React.useRef(null);

  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    console.log(newFile)
    if (newFile != null) {
      const url = URL.createObjectURL(newFile);
      props.handleOnImageChange(url, newFile, keyName)
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------

  return (
    <>
      <label className="form-label color-label">
        <FormattedMessage id={props.label} />
      </label>
      <img className="franchise-type-img" src={props.img} alt={props.label} />
      <div className="d-flex justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-outline-primary"
          onClick={handleEditImageClick}
        >
          <FormattedMessage id="changeImageLbl" />
        </button>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleOnImageChange}
        name="file"
        hidden="hidden"
        accept="image/*"
      />
    </>
  );
}
