import React from "react";
import PropTypes from "prop-types";
import FranchiseTypeListPage from "../FranchiseType/FranchiseTypeListPage/FranchiseTypeListPage";
import InvoiceSchemaListPage from "../Invoice Schemas/InvoiceSchemaListPage/InvoiceSchemaListPage";
import CustomTab from "../../components/CustomTab/CustomTab";

import FranchisePage from "../Franchise/FranchisePage/FranchisePage";
import "../../index.scss";
import { FormattedMessage } from "react-intl";

export default function MyFranchisesPage() {
  const components = [
    {
      navTitle: "franchiseNav",
      component: <FranchisePage />,
      url: "puntos-de-venta",
    },
    {
      navTitle: "franchiseTypeNav",
      component: <FranchiseTypeListPage />,
      url: "tipos-de-punto-de-venta",
    },
    {
      navTitle: "invoiceSchemaNav",
      component: <InvoiceSchemaListPage />,
      url: "resoluciones-facturacion",
    },
  ];
  return (
    <div>
      <main className="section-body">
        <h1 className="section-title">
          <FormattedMessage id="franchiseTitle" />
        </h1>
        {components && <CustomTab props={components}/>}
      </main>
    </div>
  );
}
