import StockPage from "../StockPage";
import {FormattedMessage} from "react-intl";
import CustomTab from "../../../components/CustomTab/CustomTab";
import React from "react";
import CriticalProducts from "../CriticalProducts";

export default function CritialProviderPage() {
    const components = [
        {
            navTitle: "TODOS LOS PROVEEDORES",
            component: <StockPage state={1} />
        },
        {
            navTitle: "REQUIEREN ABASTECIMIENTO",
            component: <CriticalProducts />
        }
    ];
    return (
        <div>
            {components && <CustomTab props={components}/>}
        </div>
    );
};