/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    hello: 'Hola',
    managementTitle: 'GESTIÓN',

    paymentsTitle: 'PAGOS',
    myProfileTitle: 'Mi perfil',
    logOutTitle: 'Cerrar sesión',
    helpTitle: 'Ayuda',
    franchiseTitle: 'Mis puntos de venta',
    workersTitle: 'Mis trabajadores',
    productsTitle: 'Mis productos',
    marketingTitle: 'Marketing',
    consolidatedTitle: 'CONSOLIDADOS',
    paymentMethodTitle: 'Mis métodos de pago',
    billingTitle: 'Mi historial de pago ',
    operationalReportsTitle: 'Informes operativos',
    salesBalanceTitle:"Reporte de ventas",
    usageIndicatiorsTitle: 'Indicadores de uso',
    franchiseNav: 'PUNTOS DE VENTA',
    franchiseTypeNav: 'TIPOS DE FRANQUICIA',
    invoiceSchemaNav: 'RESOLUCIONES DE FACTURACIÓN',
    createNewFranchise: 'Crear nuevo punto de venta',
    createNewWorker: 'Crear trabajador',
    createNewProduct: 'Crear nuevo producto',
    createNewInvoiceSchema: 'Crear resolución de facturación',
    createNewIngredient: 'Crear nuevo ingrediente',
    createProductCategory: 'Crear nueva categoría',
    createFranchiseType: 'Crear tipo de franquicia',
    createNewCompany: 'Crear nueva compañía',
    createNewSupervisor: 'Crear nuevo supervisor',
    editWorker: 'Editar trabajador',
    editFranchise: 'Editar punto de venta',
    editIngredient: 'Editar ingrediente',
    editProductCategory: 'Editar categoría',
    editFranchiseType: 'Editar tipo de franquicia',
    reportsOperatives: 'Informes operativos',
    editSupervisor: 'Editar supervisor',
    stockTitle:"Stock",
    //Titles
    operatorTitle: 'Mis trabajadores',
    productTitle: 'Mis productos',
    ingredientsTitle: 'Ingredientes',
    compositionTitle: 'Composición',
    businessDataTitle: 'Datos de tu negocio',
    userDataTitle: 'Datos de usuario',
    credentialsTitle: 'Establece tu contraseña',
    welcomeTitle: '¡Bienvenido!',
    companiesTitle: 'Compañías',
    supervisorsTtile: 'Supervisores',
    inventoryTitle: 'Gestión de inventarios',
    cashTitle:"Seguimiento de efectivo",
    movementsHistory: 'Histórico de movimientos',
    forceInventory: 'Forzar inventario',
    editProduct: 'Editar producto',
    editNewInvoiceSchema: 'Editar resolución de facturación',
    importFormantTitle: 'Importar formato de inventario inicial',
    errorsInFormtTitle: 'Errores importando el inventario inicial',
    reasonInventorySubTitle: 'Motivo',
    last_forced_movement: 'Última actualización forzada: ',
    allSales: 'Total entradas',
    allBuys: 'Total salidas',
    all: 'TOTAL',
    inventoryManager: 'GESTOR DE INVENTARIOS',
    //Alert Labels
    uptadedProductAlertLbl: '¡El producto fue actualizado!',
    updateInventoryAlerLbl: '¡Inventario actualizado!',
    //Modal labels
    createdProductLbl: '¡Producto creado!',
    whatNowLbl: '¿Qué deseas hacer ahora?',
    createOtherProductLbl: 'CREAR OTRO PRODUCTO',
    setUpThisProduct: 'configurar este producto',
    //Navs
    productsNav: 'PRODUCTOS',
    ingredientsNav: 'INGREDIENTES',
    categoriesNav: 'CATEGORÍAS',
    //Form labels
    nameLbl: 'Nombre',
    descriptionLbl: 'Descripción',
    franchiseTypeLbl: 'Tipo de franquicia',
    prefixLbl: 'Prefijo',
    cityLbl: 'Ciudad',
    countryLbl: 'País',
    immediateDeliveryLbl: 'Entrega inmediata',
    locationLbl: 'Ubicación',
    saveFranchiseLbl: 'GUARDAR PUNTO DE VENTA',
    cancelLbl: 'CANCELAR',


    typeOfRange: "Selecciona el tipo de rango a analizar",
    selectRangeDate:" Selecciona el rango de fechas que deseas",

    cashRegisterLbl: "Caja registradora",

    ivaModeLbl: "Tiene Iva",
    ipoModeLbl: "Tiene Ipoconsumo",
    fixIpoLbl: "Inpoconsumo Fijo",
    ivaPercentageLbl: "Porcentaje de Iva",
    internalCodeLbl: "Código interno",
    insumosNav: "Insumos",

    userLbl: 'Usuario',
    productLbl: 'Producto',
    reportDescriptLbl: 'Descripción del informe',
    fullNameLbl: 'Nombre y apellido',
    firstNameLbl: 'Nombre',
    lastNameLbl: 'Apellido',
    passwordLbl: 'Contraseña',
    repeatPasswordLbl: 'Repetir contraseña',
    changePasswordLbl: 'CAMBIAR CONTRASEÑA',
    actualPasswordLbl: 'Contraseña actual',
    newPasswordLbl: 'Nueva contraseña',
    repeatNewPasswordLbl: 'Repetir nueva contraseña',
    emailLbl: 'Correo electrónico',
    franchiseLbl: 'Punto de venta',
    addFranchiseLbl: 'Agregar punto de venta',
    reportToGenerate: 'Informe a generar',
    franchisesLbl: 'Puntos de venta',
    businessNameLbl: 'Nombre de tu negocio',
    phoneLbl: 'Teléfono',
    inventoryControlLbl: 'Controlable por inventario',
    customerVisibleLbl: 'Visible para clientes',
    quantityModeLbl: 'Precio por unidad de medida',
    categoryLbl: 'Categoría',
    barCodeLbl: 'Código de barras',
    sellPriceLbl: 'Precio de venta',
    quantityLbl: 'Candidad',
    ingredientLbl: 'Ingrediente',
    unityLbl: 'Unidad',
    saveIngredientLbl: 'GUARDAR INGREDIENTE',
    saveProductCategoryLbl: 'GUARDAR CATEGORÍA',
    logOutLbl: 'CERRAR SESIÓN',
    uploadInventoryLbl: 'SUBIR INVENTARIO INICIAL',
    tryagaibLbl: 'INTENTARLO DE NUEVO',
    unitLbl: 'Unidad',
    inventoryReversibleLbl: 'Reversible por inventario',
    ivaLbl: 'IVA',
    typeLbl: 'Tipo',
    priorityLbl: 'Prioridad',
    primaryColorLbl: 'Color primario',
    lightPrimaryColorLbl: 'Color primario claro',
    darkPrimaryColorLbl: 'Color primario oscuro',
    secondaryColorLbl: 'Color secundario',
    lightSecondaryColorLbl: 'Color secundario claro',
    darkSecondaryColorLbl: 'Color secundario oscuro',
    principalImageLbl: 'Imagen Principal',
    toolbarImageLbl: 'Imagen de barra de herramientas',
    receiptImageLbl: 'Imagen de factura',
    //Labels invoice schema
    nameComercialLbl: 'Nombre comercial',
    genericTaxLbl: 'Impuesto genérico',
    extraLbl: 'Extra',
    addressLbl: 'Dirección',
    numberLbl: 'Número',
    nitLbl: 'Nit',
    minValuelLbl: 'Valor mínimo',
    maxValueLbl: 'Valor máximo',
    initialValue: 'Valor inicial',
    initialDateLbl: 'Fecha de inicio',
    finalDateLbl: 'Fecha de fin',
    choseeDateLbl: 'Seleccione una fecha',
    choseeRangeDateLbl: "Seleccione un rango de fechas",
    ciiuLbl: 'CIIU',
    aprovalDateLbl: 'Fecha de aprobación',
    isPayrollLbl: 'Es una plantilla',
    salesGoal: "Meta de ventas mensual",
    indicatorLbl:"Indicador a mostrar",

    //Company form
    timezoneLbl: 'Zona horaria',
    freedemoLbl: 'Inicia con demo gratis',
    managerAccountLbl: 'Manager account',
    facebookPageIdLbl: 'Facebook page id',
    instagramActorIdLbl: 'Instagram actor id',
    companyLbl: 'Compañía',
    //Inventory
    graphLbl: 'Gráfico',
    tableLbl: 'Tabla',
    returnedLbl: 'Devolución',
    transferLbl: 'Traslado',
    giftLbl: 'Cortesía',
    lostLbl: 'Perdida',
    otherLbl: 'Otro',
    commentaryLbl: 'Comentario',
    referenceNumberLbl: 'Numero de referencia',
    relateLbl: "Relacionar",

    //Buttons
    saveWorkerLbl: 'GUARDAR TRABAJADOR',
    saveProductLbl: 'GUARDAR PRODUCTO',
    createWorkerLbl: 'CREAR TRABAJADOR',
    createFranchiseLbl: 'CREAR PUNTO DE VENTA',
    createProductLbl: 'CREAR PRODUCTO',
    saveCompanyLbl: 'GUARDAR COMPAÑÍA',
    createSupervisorLbl: 'GUARDAR SUPERVISOR',
    saveSupervisorLbl: 'GUARDAR SUPERVISOR',
    loginBtn: 'INICIAR SESIÓN',
    addIngredientLbl: 'AGREGAR',
    saveFranchiseTypeLbl: 'GUARDAR TIPO DE FRANQUICIA',

    createIngredientLbl: 'CREAR INGREDIENTE',
    createProductCategoryLbl: 'CREAR NUEVA CATEGORÍA',
    createFranchiseTypeLbl: 'CREAR TIPO DE FRANQUICIA',
    changeImageLbl: 'CAMBIAR IMAGEN',
    acceptLbl: 'ACEPTAR',
    readyLbl: '¡LISTO!',

    createInvoiceSchemaLbl: 'CREAR RESOLUCIÓN',
    saveInvoiceSchemaLbl: 'GUARDAR RESOLUCIÓN',
    downloadReportLbl: 'DESCARGAR INFORME',
    newReportLbl: 'NUEVO INFORME',
    //Calendar
    janLbl: 'Enero',
    febLbl: 'Febrero',
    marLbl: 'Marzo',
    aprLbl: 'Abril',
    mayLbl: 'Mayo',
    junLbl: 'Junio',
    julLbl: 'Julio',
    augLbl: 'Agosto',
    sepLbl: 'Septiembre',
    octLbl: 'Octubre',
    novLbl: 'Noviembre',
    decLbl: 'Diciembre',

    mondayLbl: 'Lunes',
    tuesdayLbl: 'Martes',
    wednesdayLbl: 'Miércoles',
    thursdayLbl: 'Jueves',
    fridayLbl: 'Viernes',
    saturdayLbl: 'Sábado',
    sundayLbl: 'Domingo',
    //Form labels

    // Table Headers
    nameHeader: 'Nombre',
    categoryHeader: 'Categoría',
    franchiseTypeHeader: 'Tipo de franquicia',
    unitHeader: 'Unidad',
    actionsHeader: 'Acciones',
    typeHeader: 'Tipo',
    numberHeader: 'Número',
    minValueHeader: 'Valor mínimo',
    maxValueHeader: 'Valor máximo',
    startValueHeader: 'Valor inicial',
    approvedDateHeader: 'Fecha aprobación',
    usernameHeader: 'Usuario',
    nameLastnameHeader: 'Nombre y apellido',
    emailHeader: 'Correo',
    companyHeader: 'Compañía',
    lastSessionHeader: 'Último inicio de sesión',
    lastMovementHeader: 'Último movimiento',
    currentQuantityHeader: 'Cantidad actual',
    dateHeader: 'Fecha',
    typeMovementHeader: 'Tipo de movimiento',
    quantityHeader: 'Cantidad',
    balanceQuantityHeader: 'Saldo cantidad',
    referenceHeader: 'Referencia',
    movementTypeHeader:'Tipo de movimiento',
    valueHeader:'Valor',
    cashAvailableHeader:'Efectivo disponible',
    barcodeHeader:"Codigó de barras",

    /**
     * Demo
     */
    gastronomicLbl: 'Gastronómico',
    storeLbl: 'Tienda',
    franchiseNameLbl: 'Escribe un nombre para tu local',
    startLbl: '¡EMPEZAR!',

    cityHeader: 'Ciudad',
    countryHeader: 'País',
    demoProgressHeader: 'Progreso de demo',
    stateHeader: 'Estado',

    // Descriptions
    invalidLinkDescription: 'Este enlace no es válido',
    welcomeDescription:
      'Ahora podrás disfrutar de los beneficios de la plataforma Kurúah, desde cualquier lugar. Ingresa a nuestra plataforma web, o descarga las aplicación desde Play Store o AppStore.',
    gastronomicDescription:
      'Restaurantes, bares, cafés, y en general negocios cuyo producto requiere una preparación previa',
    storeDescription:
      'Supermercados, ropa, accesorios, repuestos, y cualquier tipo de negocio que distribuya productos al detal',
    welcomeLbl: '¡Bienvenido,',
    kindBusinessLbl: '¿Qué tipo de negocio tienes?',
    allReadyLbl: '¡Todo listo!',
    operatorDescriptionDemo:
      "Para empezar a registrar tus ventas, ingresa desde la aplicación 'Kurúah' para Android (disponible en el Play Store) con el siguiente",
    usernameLbl: 'Username: ',
    passwordDemoLbl: 'Constraseña: ',
    supervisorDescriptionDemo:
      'Para ver las ventas reportadas, ingresa desde la aplicación "Kurúah" para Android (disponible en el Play Store) y iOS (disponible en el App Store) con el siguiente usuario (es el mismo que usas para ingresar a la plataforma web):',
    dragndropDescription:
      'Arrastra o suelta, o haga clic para seleccionar el formato de importación',

    //Dropdown menu
    importProductsTitle: "Importar formato de productos",
    errorsImportingFormat: "Errores improtanto el formato",
    uploadFormatLbl: "IMPORTAR FORMATO",
    updateProductsAlerLbl:"¡Productos creados!"
  }
};
