import {
  URL_DOMAIN,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut
} from "../globals";

const URL_FRANCHISES = URL_DOMAIN + URL_P2[0];

const supervisorId = localStorage.getItem("fk_supervisor");
/**
 * Franchises
 * @returns    
 */

const getFranchises = async () => {
  
  return fetch(
    URL_FRANCHISES +
      "/get-franchises-of-supervisor?fk_supervisor=" +
      supervisorId,
    requestOptionsGet
  ).then(getJSON);
};

const getFranchisesById = async (id) => {
  return fetch(
    URL_FRANCHISES + "/get-franchise?fk_franchise=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const postFranchise = async (formData) => {
  formData.append("fk_supervisor", supervisorId)
  requestOptionsPost.body = formData;
  return fetch(URL_FRANCHISES + "/create-franchise", requestOptionsPost).then(
    getJSON
  );
};

const putFranchise = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_FRANCHISES + "/edit-franchise", requestOptionsPut).then(
    getJSON
  );
};

const put2forTestFranchise = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_FRANCHISES + "/edit-franchise", requestOptionsPut).then(
    getJSON
  );
};
export { getFranchises, getFranchisesById, postFranchise, putFranchise };
