import React from "react";
import { Link } from "react-router-dom";
import OperatorDefaultImg from "../../../assets/img/default/operario.jpg";
import EditIcon from "../../../assets/icons/edit-icon-24px.svg";
import SeeIcon from "../../../assets/icons/baseline-info-24px.svg";
import "./OperatorCard.scss";

const OperatorCard = (operator) => {
  let name = operator["operator"]["user"].last_name
    ? operator["operator"]["user"].first_name +
      " " +
      operator["operator"]["user"].last_name
    : operator["operator"]["user"].first_name;
  let franchise = operator["operator"]["franchise"].name;
  let profileImg = operator["operator"].image
    ? operator["operator"].image
    : OperatorDefaultImg;

  let goOperator = () => {
    localStorage.setItem("fk_operator", operator["operator"].id);
  };
  return (
    <div className="gridempleado card center">
      <img src={profileImg} className="card-img-top round" alt={name + "_img"} />
      <div className="card-body informacionempleado row">
        <div className="textotarjetaempleado col">
          <div className="nombreempleado">{name}</div>
          <div className="puntoventaempleado">{franchise}</div>
        </div>
        <div className="icontarjeta col-3">
{/**        <Link className="" to={`/trabajadores/${name}`} onClick={goOperator}>
            <img src={SeeIcon} alt="see_icon" />
          </Link>  */}
          <Link className="" to={`/trabajadores/${name}/edit`} onClick={goOperator}>
            <img src={EditIcon} alt="edit_icon" />
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default OperatorCard;
