import React from "react";
import DownloadLink from "react-download-link";
import {
  requestOptionsGet,
} from "../../services/globals";

const DownloadLinkCustom = (props) => {
  let urlDownload = props.url;

  let fileName = props.fileName;

  let label = props.label;
  console.log('ahhhhh en el download');
  let getDataFromURL = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(urlDownload, requestOptionsGet)
          .then((response) => response.json())
          .then((data) => {
            console.log(data.url);
            // resolve(data.url)
            var element = document.createElement('a');
            element.setAttribute('href', data.url);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
          });
      });
    }, 2000);
  return (
    <DownloadLink
      label={label}
      filename={fileName}
      exportFile={() => Promise.resolve(getDataFromURL())}
    />
  );
};

export default DownloadLinkCustom;
