import "./ProvidersCard.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ProvidersCard = ({ provider, activeProvider, setActiveProvider }) => {
  let providerName = provider.name.charAt(0).toUpperCase() + provider.name.slice(1);
  const handleClick = (event) => {
    event.preventDefault();
    setActiveProvider(provider);
  };
  return (
    <div
      className={`card-container ${
        activeProvider.nit === provider.nit ? "card-active" : ""
      }`}
      onClick={handleClick}
    >
      <div>
        <h5 className="title-provider">
          {provider.name.length < 24 ? providerName : providerName.substring(0,20) +"..."}
        </h5>
        <p className="info-card">Nit: {provider.nit}</p>
        <p className="info-card especial"> {provider.products? provider.products[0].length:"" } productos</p>

      </div>
      <div className="arrow-center"> 
        <ArrowForwardIosIcon className="icon-providers" />
      </div>
    </div>
  );
};

export default ProvidersCard;
