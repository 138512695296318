import "./LastSaleItem.scss";
import CurrencyFormat from "react-currency-format";

const LastSaleItem = (prop) => {
  let info = prop["prop"];
  let customerName;
  if(info.customer){
    customerName = info.customer.last_name
    ? info.customer.full_name + " " + info.customer.last_name
    : info.customer.full_name;
  }
  else{
    customerName = "Consumidor generico"
  }
  

  let date = info.sale_date.split(" ")

  let img_cash  = info.payment_method ===  0?  "https://e7.pngegg.com/pngimages/416/600/png-clipart-money-bag-bank-computer-icons-credit-card-cash-coupon-material-angle-rectangle-thumbnail.png": "https://cdn1.iconfinder.com/data/icons/material-design-icons-light/24/credit-card-512.png";
  return (
    <div className="row sale-item-margin">
      <div className="col-2 center-img">
        <img
          src={img_cash}
          alt="img_sale"
          className="img-last-sale-item"
        />
      </div>
      <div className="col sale-item-info-container">
        <div className="row">
          <div className="col ">
            <p className="size-1rem no-margin">{customerName}</p>
            <p className="no-margin sale-item-price">
              <CurrencyFormat
                value={info.sale_value}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </p>
            <p className="size-1rem no-margin">{info.franchise.name}</p>
          </div>
          <div className="col-4 date-div">
            <p className="sale-date size-1rem no-margin">Hoy, {date[1]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastSaleItem;
