import React, { useState, useEffect } from "react";


const Context = React.createContext({});

export function UserContextProvider({ children }) {
  const [token, setToken] = useState(
    () => window.localStorage.getItem('bckt')
    );
  const [supervisorId, setSupervisorId] = useState([]);
  const [supervisorProfile, setSupervisorProfile] = useState(
    () => JSON.parse( window.localStorage.getItem("profile")) 
  )
  const [supervisorImage, setSupervisorImage] = useState(window.localStorage.getItem("profilePicture"))
  
  const [userRole] = useState(window.localStorage.getItem("role"))

  const [demoProgress, setDemoProgress] = useState(window.localStorage.getItem("demo_progress"));

  const [suspensionCompany, setSuspensionCompany] = useState(window.localStorage.getItem("suspension"))

  return (
    <Context.Provider value={{ token, setToken, supervisorId, setSupervisorId,supervisorProfile, supervisorImage, setSupervisorImage, userRole, demoProgress, setDemoProgress, suspensionCompany, setSuspensionCompany }}>{children}</Context.Provider>
  );
}

export default Context;
