import { Select, MenuItem, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import SupervisorDefaultImg from "../../../assets/icons/DEFAULT IMG/Operator_img.jpg";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import { getCompaniesSimple } from "../../../services/utilsCompany/utilsCompany";
import "./SupervisorForm.scss";
import "../../../index.scss";
import { getSupervisorById, postSupervisor, putSupervisor } from "../../../services/utilsSupervisor/utilsSupervisor";
const SupervisorForm = () => {
  /**
   * Variables & Constants
   */
  const [companiesList, setCompaniesList] = useState([]);

  const [supervisor, setSupervisor] = useState({});

  const [imgSource, setImgSource] = useState(SupervisorDefaultImg);

  const [formTitle, setFormTitle] = useState("createNewSupervisor");

  const [submitLabel, setSubmitLabel] = useState("createSupervisorLbl");

  let newProfilePicture;

  const [loading, setLoading] = useState(true);

  const [passwordEdited, setPasswordEdited] = useState({
    password: true,
    repeat_password: true,
  });

  const [submitted, setSubmitted] = useState(false);

  const passwordsMatch = function () {
    return supervisor.password === supervisor.repeat_password;
  };

  const hiddenFileInput = React.useRef(null);

  const location = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  /**
   * Async Calls
   * @param {*} event 
   */

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };
  const fetchData = async () => {

    getCompaniesSimple().then((data)=>{
      setCompaniesList(data["companies"]);
      setLoading(false);
    });

    if(location.pathname.includes("edit")){
      let supervisorId = localStorage.getItem("fk_supervisor");
      setPasswordEdited({ password: false, repeat_password: false });
      setFormTitle("editSupervisor");
      setSubmitLabel("saveSupervisorLbl");

      getSupervisorById(supervisorId).then((data) =>{
        console.log(data, "fetch supervisor");
        let supervisorData = data.supervisor;
        let userData = supervisorData.user;
        setSupervisor(
          {
            username: userData.username,
            first_name: userData.first_name,
            last_name: userData.last_name,
            password: "        ",
            repeat_password: "        ",
            fk_company: supervisorData.company.id,
            email: userData.email,
          });
          supervisorData.image ? setImgSource(supervisorData.image)
          : setImgSource(imgSource);

        //TODO missing image
      })
    }
  };
  
  useEffect(() => {
    fetchData().then(() => {});
  }, []);
  /**
   * Handlers
   * @param {*} event 
   * @param {*} reason 
   * @param {*} success 
   * @returns 
   */
  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleFirstTimePasswordEdit = (event) => {
    setSupervisor({
      ...supervisor,
      [event.target.name]: event.target.value.trim(),
    });
    setPasswordEdited({ ...passwordEdited, [event.target.name]: true });
  };

  const handleInputChangeInner = (event) => {
    setSupervisor({ ...supervisor, [event.target.name]: event.target.value });
  };

  
  const handleEditImageClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleOnImageChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile != null) {
      setSupervisor({ ...supervisor, [event.target.name]: event.target.files[0] });
      newProfilePicture = newFile;
      const url = URL.createObjectURL(newProfilePicture);
      setImgSource(url);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in supervisor) {
      if (typeof supervisor[key] == File) {
        formDataBody.append(key, supervisor[key], "profilePicture.jpg");
      } else {
        formDataBody.append(key, supervisor[key]);
      }
    }
    if(location.pathname.includes("edit")){
      let supervisorId = localStorage.getItem("fk_supervisor");
      formDataBody.append("fk_supervisor", supervisorId);
      putSupervisor(formDataBody).then((data)=>{
        if(data.error === undefined){
          setSnackbarMessage(<Alert severity="success">Se ha actualizado el supervisor con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }else{
      if(passwordsMatch()){
        postSupervisor(formDataBody).then((data)=>{
          if(data.error === undefined){
            setSnackbarMessage(<Alert severity="success">Se ha creado el supervisor con éxito</Alert>);
            setOpenSnackbar(true);
          } else {
            setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        })
      }
      else{
        setSnackbarMessage(<Alert severity="error">Error: Las contraseñas no son iguales</Alert>);
        setOpenSnackbar(true);
        setSubmitted(false)
      }
    }
  }

  return (
    <main className="section-body">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarMessage}
      </Snackbar>
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && (
        <form
          onSubmit={handleSubmit}
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="d-flex justify-content-center mb-4">
            <img className="profile-picture" src={imgSource} alt="Avatar" />
            <div className="icon-container d-flex justify-content-center align-items-center">
              <img
                className="icon"
                src={EditIcon}
                alt="edit_icon"
                onClick={handleEditImageClick}
              />
            </div>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleOnImageChange}
            name="file"
            hidden="hidden"
            accept="image/*"
          />
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="unsernameSupervisor"
                  label={<FormattedMessage id="userLbl" />}
                  value={supervisor.username ? supervisor.username : ""}
                  name="username"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                type="email"
                  required
                  className="form-control"
                  id="emailSupervisor"
                  label={<FormattedMessage id="emailLbl" />}
                  value={supervisor.email ? supervisor.email : ""}
                  name="email"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="firstNameSupervisor"
                  label={<FormattedMessage id="firstNameLbl" />}
                  value={supervisor.first_name ? supervisor.first_name : ""}
                  name="first_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="lastNameSupervisor"
                  label={<FormattedMessage id="lastNameLbl" />}
                  value={supervisor.last_name ? supervisor.last_name : ""}
                  name="last_name"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>

          {!location.pathname.includes("edit") && (
            <div className="row">
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="passwordSupervisor"
                    label={<FormattedMessage id="passwordLbl" />}
                    value={supervisor.password ? supervisor.password : ""}
                    name="password"
                    onChange={
                      passwordEdited.password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-4">
                  <TextField
                    required
                    type="password"
                    className="form-control"
                    id="repeatPasswordSupervisor"
                    label={<FormattedMessage id="repeatPasswordLbl" />}
                    value={
                      supervisor.repeat_password
                        ? supervisor.repeat_password
                        : ""
                    }
                    name="repeat_password"
                    onChange={
                      passwordEdited.repeat_password
                        ? handleInputChangeInner
                        : handleFirstTimePasswordEdit
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-6 mb-4">
              <label className="form-label" htmlFor="franchiseInput">
                <FormattedMessage id="companyLbl" />
              </label>
              <Select
                required
                value={supervisor.fk_company ? supervisor.fk_company : ""}
                name="fk_company"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="company"
              >
                {companiesList &&
                  companiesList.map((franchise, i) => (
                    <MenuItem key={i} value={franchise.id}>
                      {franchise.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-12 d-flex justify-content-end">
              <Link className="" to={`/supervisores`}>
                <button className="btn btn-outline-primary m-2">
                  <FormattedMessage id="cancelLbl" />
                </button>
              </Link>
              {/* <Link className="" to={`/trabajadores`}> */}
              <button
                className="btn btn-primary m-2"
                type="submit"
                disabled={submitted}
              >
                {!submitted && <FormattedMessage id={submitLabel} />}
                {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </main>
  );
};

export default SupervisorForm;
