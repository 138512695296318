import React, { useEffect, useState } from "react";
import "../../../index.scss";
import { FormattedMessage } from "react-intl";
import FranchiseDefaultImg from "../../../assets/img/default/punto-de-venta.jpg";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "../../../assets/icons/white-create-24px.svg";
import {TextField, FormControlLabel, Switch, Snackbar} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getFranchises, getFranchisesById, postFranchise, putFranchise } from "../../../services/utilsFranchise/utilsFranchise";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";
// import GoogleApiWrapper from "../../../components/supervisor/GoogleMap/Map";
import SimpleMap from "../../../components/supervisor/GoogleMap/GoogleMap";
import './FranchiseForm.scss';
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const filter = createFilterOptions();

export const FranchiseForm = () => {





  // ----------------------------------------------------
    // ------------- Variables & Constants ----------------
    // ----------------------------------------------------

    const [franchiseTypeList, setFranchiseTypeList] = useState([]);

    const [imgSource, setImgSource] = useState(FranchiseDefaultImg)

    const [franchise, setFranchise] = useState({});

    const [loading, setLoading] = useState(true);

    const [formTitle, setFormTitle] = useState("createNewFranchise");

    const [submitLabel, setSubmitLabel] = useState("createFranchiseLbl");

    const [franchiseType, setFranchiseType] = useState("");

    const [sellPrice, setSellPrice] = useState();


    const [map, setMap] = useState(<div></div>)
    
    const [latLng, setLatLng] = useState({})
    
    let newProfilePicture;

    const hiddenFileInput = React.useRef(null);
    
    const urlLocation = useLocation();

    const [snackbarMessage, setSnackbarMessage] = useState(<Alert severity="success">NONE</Alert>)

    const [successSnackbar, setSuccessSnackbar] = useState(false)

    const [openSnackbar, setOpenSnackbar] = useState(false);
    
    const [submitted, setSubmitted] = useState(false);

    const history = useHistory()

    // ----------------------------------------------------
    // ------------------ Async calls ---------------------
    // ----------------------------------------------------

    const fetchData = async () => {
        getFranchiseTypes().then((data) => {
            setFranchiseTypeList(data["franchise_types"]);
            setLoading(false);
        });
        
        if(urlLocation.pathname.includes("edit")){
          let franchiseId = localStorage.getItem("fk_franchise")
          setFormTitle("editFranchise")
          setSubmitLabel("saveFranchiseLbl")

          getFranchisesById(franchiseId).then((data)=>{
            console.log("This is data", data)
            let franchiseData = data.franchise
            setFranchise(franchiseData)
            setFranchiseType(franchiseData.franchise_type)
              setSellPrice(franchiseData.sales_goal);
            franchiseData.image ? setImgSource(franchiseData.image): setImgSource(imgSource);
            if(franchiseData.latitude && franchiseData.longitude){
              setMap(<SimpleMap center={{lat:franchiseData.latitude, lng:franchiseData.longitude}} handleLocationChanged={handleLocationChanged} />)
            } else {
              setMap(<SimpleMap handleLocationChanged={handleLocationChanged} />)
            }
          })
        
        
        } else {
          setMap(<SimpleMap handleLocationChanged={handleLocationChanged} />)
        }

        
      };

      const getCurrentLocation = ()=>{
        navigator.geolocation.getCurrentPosition(function(position) {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          
        });
      }

      useEffect(() => {
        getCurrentLocation()
        fetchData().then(()=>{
        });
      }, []);
    

    // ----------------------------------------------------
    // ------------------- Handlers -----------------------
    // ----------------------------------------------------

    const handleEditImageClick = (event) => {
        hiddenFileInput.current.click()
    }

    const handleSnackbarClose = (event, reason, success=false) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnackbar(false);
      if (successSnackbar){
        history.push("/puntos-de-venta");
      }
    };

    const handleOnImageChange = (event)=>{
        const newFile = event.target.files[0]
        if(newFile != null){
            setFranchise({...franchise, [event.target.name]: event.target.files[0]})
            newProfilePicture = newFile
            const url = URL.createObjectURL(newProfilePicture)
            setImgSource(url)
        }
    }

    const handleInputChangeInner = (event)=>{
      console.log(event.target.name)
      console.log(event.target.value)
      setFranchise({...franchise, [event.target.name]: event.target.value});
    }

    const handleImmediateDeliveryChange = (event)=>{
      console.log(event.target.name)
      console.log(event.target.checked)
      setFranchise({...franchise, [event.target.name]: event.target.checked?1:0});
    }

    const handleSubmit = (event)=>{
      event.preventDefault()
      setSubmitted(true)
      let formDataBody = new FormData();
      for(const key in franchise){
        if(typeof franchise[key] == File){
          formDataBody.append(key, franchise[key], "profilePicture.jpg")
        } else if(key==="immediate_delivery"){
          console.log(franchise[key])
          formDataBody.append(key, franchise[key]?1:0)
        }else if (key === "sales_goal" && typeof franchise[key] === "string") {
            let sellPrice = franchise[key];
            let finalPrice = sellPrice.replaceAll(",", "");
            let finalFinal = finalPrice.replace(".","");
            console.log("YA DEBERIA ESTAR ARREGLADO", finalPrice)
            formDataBody.append(key, finalPrice);
        } else if(franchise[key]!==undefined){
          console.log(key,franchise[key])
          formDataBody.append(key, franchise[key])
        }
      }
      if(franchiseType.id){
        formDataBody.append("fk_franchise_type", franchiseType.id)
      } else {
        formDataBody.append("franchise_type_name", franchiseType.title)
      }
      if((latLng.lat!==undefined && latLng.lng!==undefined)){
        console.log("entré")
        formDataBody.append("latitude", latLng.lat)
        formDataBody.append("longitude", latLng.lng)
      }
      console.log(franchiseType)
      if(urlLocation.pathname.includes("edit")){
        let franchiseId = localStorage.getItem("fk_franchise")
        formDataBody.append("fk_franchise", franchiseId)
        putFranchise(formDataBody).then((data)=>{
          console.log(data)
          if(data.error === undefined){
            setSuccessSnackbar(true)
            setSnackbarMessage(<Alert severity="success">Se ha actualizado el punto de venta con éxito</Alert>);
            setOpenSnackbar(true);
          } else {
            setSuccessSnackbar(false)
            setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        })
      } else {
        postFranchise(formDataBody).then((data)=>{
          if(data.error === undefined){
            setSuccessSnackbar(true)
            setSnackbarMessage(<Alert severity="success">Se ha creado el punto de venta con éxito</Alert>);
            setOpenSnackbar(true);
          } else { 
            setSuccessSnackbar(false)
            setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
            setOpenSnackbar(true);
          }
          setSubmitted(false);
        })
      }
      
    }

    const checkKeyDown = (event) => {
      if (event.code === 'Enter') event.preventDefault();
    }

    const handleLocationChanged = (lat,lng)=>{
      console.log(lat, lng)
      console.log(franchise)
      setLatLng({lat:lat, lng:lng})
      console.log(latLng)
    }


    // ----------------------------------------------------
    // -------------------- Render ------------------------
    // ----------------------------------------------------
  return (
    <>
    <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}

                >
                 {snackbarMessage}
                </Snackbar>
    <main className="section-body">
      
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && 
        <form onSubmit={handleSubmit} className="row g-3" onKeyDown={(e) => checkKeyDown(e)}>
        <div className="d-flex justify-content-center mb-4">
            <img className="profile-picture" src={ imgSource } alt="Avatar" />
            <div className="icon-container d-flex justify-content-center align-items-center">
                <img className="icon" src={EditIcon} alt="edit_icon" onClick={handleEditImageClick} />
            </div>
        </div> 
        <input type="file" ref={hiddenFileInput} onChange={handleOnImageChange} name="file" hidden="hidden" accept="image/*" />
          <div className="col-6 mb-3">
            <TextField 
              required 
              className="form-control" 
              id="standard-basic" 
              label={<FormattedMessage id="nameLbl" />} 
              value={franchise.name?franchise.name:""} 
              name="name"
              onChange={handleInputChangeInner} />
          </div>
          <div className="mb-3 ">
            <TextField 
              required 
              className="form-control description-container" 
              id="standard-basic" 
              label={<FormattedMessage id="descriptionLbl" />} 
              value={franchise.description?franchise.description:""} 
              name="description"
              onChange={handleInputChangeInner}
              rows={4}
              multiline
              />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <Autocomplete
                    disabled={urlLocation.pathname.includes("edit")}
                    value={franchiseType}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setFranchiseType({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setFranchiseType({
                          title: newValue.inputValue,
                        });
                      } else {
                        setFranchiseType(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
  
                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
  
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={franchiseTypeList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
  
                      if (option.title) {
                        return option.title;
                      }
  
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(option) => option.name}
                    freeSolo
                    renderInput={(params) => (
                      <TextField required {...params} label={<FormattedMessage id="franchiseTypeLbl" />} />
                    )}
                />
                
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <TextField 
                className="form-control" 
                id="standard-basic" 
                label={<FormattedMessage id="prefixLbl" />} 
                value={franchise.preffix?franchise.preffix:""} 
                name="preffix"
                onChange={handleInputChangeInner} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <TextField 
                required 
                className="form-control" 
                id="standard-basic" 
                label={<FormattedMessage id="cityLbl" />} 
                value={franchise.city?franchise.city:""} 
                name="city"
                onChange={handleInputChangeInner} />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <TextField 
                required 
                className="form-control" 
                id="standard-basic" 
                label={<FormattedMessage id="countryLbl" />} 
                value={franchise.country?franchise.country:""} 
                name="country"
                onChange={handleInputChangeInner} />

              </div>

            </div>
          </div>
          <div className="row">
              <div className="col-6">
                  <div className="mb-3">
                      <FormControlLabel
                          control={<Switch checked={franchise.immediate_delivery?true:false} onChange={handleImmediateDeliveryChange} name="immediate_delivery" color="primary" />}
                          label={<FormattedMessage id="immediateDeliveryLbl" />}
                      />
                  </div>
              </div>
              <div className="col-6">
                  <div className="mb-3">
                      {urlLocation.pathname.includes("edit") && <CurrencyTextField
                          label={<FormattedMessage id="salesGoal"/>}
                          variant="standard"
                          className="form-control"
                          value={sellPrice}
                          name="sales_goal"
                          currencySymbol="$"
                          outputFormat="number"
                          onChange={handleInputChangeInner}

                      />}
                  </div>
              </div>

          </div>
          <div className="row">
            <div className="col-6">
              <label className="form-label">
                <FormattedMessage id="locationLbl" />
              </label>
              {map}
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-12 d-flex justify-content-end">
                
                <Link className="" to={`/puntos-de-venta`}>
                  <button className="btn btn-outline-primary m-2"> 
                  <FormattedMessage id="cancelLbl" />
                  </button>
                </Link>
                <button className="btn btn-primary m-2" type="submit">
                    {!submitted && <FormattedMessage id={submitLabel} />}
                    {submitted && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div
                          className="spinner-border spinner-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                </button>
            </div>
        </div>
        </form>
      }
      {loading && 
      <div className="d-flex justify-content-center spinner-container">
        <div className="spinner-border spinner-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
    </div>
    }
    </main>
    </>
  );
};
