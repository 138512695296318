import React, { useEffect, useState } from "react";
import CustomTable, { convertToSelect } from "../../../components/Table/Table";
import EditPrimaryIcon from "../../../assets/icons/edit-24px.svg";
import SearchBar from "../../../components/searchBar/searchBar";
import { Link } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import { editCompanySuspension, getCompanies } from "../../../services/utilsCompany/utilsCompany";
import { FormattedMessage } from "react-intl";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";



const CompanyList = () => {
    // ----------------------------------------------------
    // ------------- Variables & Constants ----------------
    // ----------------------------------------------------
    const [searchQuery, setSearchQuery] = useState("");

    const [companies, setCompanies] = useState([]);

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [pagination, setPagination] = useState({});

    const [loading, setLoading] = useState(true);

    const [tableLoading, setTableLoading] = useState(true);

    const companyTableHeaders = [
        "nameHeader",
        "cityHeader",
        "countryHeader",
        "demoProgressHeader",
        "stateHeader",
        "actionsHeader",
    ];

    const suspensionOptions = [["Al día", 0], ["Aviso de suspensión", 1], ["Suspensión parcial", 2], ["Suspensión total", 3], ["En demo", 4], ["Demo finalizado", 5]];

    const createCompanyData = (name, city, country, demoProgress, suspension, id) => {
        return {
            name,
            city,
            country,
            demoProgress: demoProgressString[demoProgress],
            suspension: convertToSelect(suspension, "suspension", handleSuspensionChange, suspensionOptions, id),
            actions: (
                <Link to={`/companias/${name}/edit`} onClick={() => goCompany(id)}><img src={EditPrimaryIcon} alt="edit" /></Link>
            ),
        };
    };

    const demoProgressString = {
        0: " - ",
        1: "Create franchise",
        2: "Create worker",
        3: "In demo",
        4: "Demo finished"
    }

    const goCompany = (id) => {
        localStorage.setItem("fk_company", id);
    };

    // ----------------------------------------------------
    // ------------------ Async calls ---------------------
    // ----------------------------------------------------
    const fetchData = async (page, rowsPerPage, searchQuery=null) => {
        setTableLoading(true)
        getCompanies(page, rowsPerPage, searchQuery).then((data) => {
            let companiesData = data.companies.map((company) =>
                createCompanyData(
                    company.name,
                    company.city,
                    company.country,
                    company.demo_progress,
                    company.suspension,
                    company.id
                )
            );
            setCompanies(companiesData);
            let paginationObject = data.pagination;
            paginationObject.rowsPerPage = rowsPerPage;
            paginationObject.page = page;
            setPagination(paginationObject);
            setLoading(false)
            setTableLoading(false)
            setPage(page)
            setRowsPerPage(rowsPerPage)
        });
    };

    useEffect(() => {
        fetchData(page, rowsPerPage,"");
    }, []);

    // ----------------------------------------------------
    // ------------------- Handlers -----------------------
    // ----------------------------------------------------
    const handleChangePage = async (newPage) => {
        await fetchData(newPage, rowsPerPage).then(() => {
            setPage(newPage);
        });
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await fetchData(0, rowsPerPage).then(() => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
        });
    };

    const handleTextSearch = async (input) => {
        setSearchQuery(input)
        fetchData(0, rowsPerPage, input)
        setPage(0);
    };

    const handleSuspensionChange = (event, rows, rowId) => {
        const target = event.target
        const companyId = parseInt(target.name.split("-")[0]);
        let formData = new FormData()
        formData.append("suspension", target.value)
        formData.append("id", companyId)
        setTableLoading(true)
        
        editCompanySuspension(formData).then((data)=>{
            const suspension = convertToSelect(target.value, "suspension", handleSuspensionChange, suspensionOptions, companyId);
            setCompanies(rows.map(el => (el.suspension.rowId === rowId ? {...el, suspension} : el)))
            setTableLoading(false)
        })
    }
    // ----------------------------------------------------
    // -------------------- Render ------------------------
    // ----------------------------------------------------
    return (
        <div>
      <main className="section-body">
        <h1 className="section-title">
          <FormattedMessage id="companiesTitle" />
        </h1>
        <>
            {loading && (
                <div className="d-flex justify-content-center spinner-container">
                    <div className="spinner-border spinner-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {!loading &&
                <>
                    <SearchBar
                        input={searchQuery}
                        onChange={handleTextSearch}
                        nombre="Compañías"
                    />
                    {tableLoading && <LinearProgress />}
                    <CustomTable
                        headers={companyTableHeaders}
                        rows={companies}
                        rowTypes={[[4, "select"]]}
                        pagination={pagination}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />

                </>
            }
        </>
        <Link to={"companias/create"}>
              <Fab size="small" className="fab" color="secondary" aria-label="edit">
                <AddIcon className="fab-icon" />
              </Fab>
            </Link>
      </main>
    </div>
        
    );
};
// [4, "select"]
export default CompanyList;
