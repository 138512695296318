import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";
import {
  FormControlLabel,
  InputAdornment,
  Checkbox,
  TextField,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { createInventoryConfrirmation } from '../../../services/utilsInventory/utilsInventory';
import { object } from 'joi';

export default function EditInventoryDialog(props) {
  const [open, setOpen] = useState(false);

  const [reason, setReason] = useState(1);

  const [openSnack, setOpenSnack] = React.useState(false);

  const [finalMessage, setFinalMessage] = useState(true);
  
  let productData = props.product;

  let fetchData = props.fetchData;
  
  const [diferenceValue, setDiferenceValue] = useState(productData.quantity);

  const [confirmationInfo, setConfirmationInfo] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiferenceValue = (event) => {
    setDiferenceValue(event.target.value - diferenceValue);
  };

  const handleReason = (event) => {
    setReason(event.target.value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
  const handleInputChangeInner = (event) => {
    setConfirmationInfo({
      ...confirmationInfo,
      [event.target.name]: event.target.value
    });
  };

  const hanldeSubmit = (event) => {
    event.preventDefault();

    var raw2 = JSON.stringify({
      comment: confirmationInfo['comment'],
      fk_franchise: props.franchiseId,
      fk_product: props.product.id,
      quantity: parseInt(confirmationInfo['quantity']),
      fk_inventory_confirmation_category: reason,
      referenced_number: confirmationInfo['referenced_number']
        ? confirmationInfo['referenced_number']
        : ''
    });

    createInventoryConfrirmation(raw2).then((data) => {
      
      setOpenSnack(true);
      let fetchData = props.fetchData;
      setTimeout(() => fetchData(props.franchiseId), 1000);
      setTimeout(() => setOpenSnack(false), 1000);
      setTimeout(() => setOpen(false), 1000);
    });
  };

  return (
    <div>
      <img
        src={props.image}
        alt="edit"
        onClick={handleClickOpen}
        className="table-icon"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {' '}
          <h2 className="section-title">
            <FormattedMessage id="forceInventory" />
          </h2>
        </DialogTitle>
        <form onSubmit={hanldeSubmit}>
          <DialogContent>
            <article className="row">
              <div className="col-6">
                <p>{productData.name}</p>
              </div>
              <div className="col-6">
                <p>
                  {productData.quantity} {productData.unit}
                </p>
              </div>
            </article>
            <TextField
              label="Nueva cantidad"
              type="number"
              name="quantity"
              fullWidth
              required
              color="primary"
              className="form-control"
              onChange={handleInputChangeInner}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {productData.unit}
                  </InputAdornment>
                )
              }}
            />
            <br />
            <br />
            <h6>
              Diferencia: {diferenceValue} {productData.unit}
            </h6>
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                <h5>
                  <FormattedMessage id="reasonInventorySubTitle" />:
                </h5>
                <RadioGroup
                  aria-label="gender"
                  name="controlled-radio-buttons-group"
                  value={reason}
                  onChange={handleReason}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="returnedLbl" />}
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label={<FormattedMessage id="transferLbl" />}
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label={<FormattedMessage id="giftLbl" />}
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label={<FormattedMessage id="lostLbl" />}
                  />
                  <FormControlLabel
                    value={5}
                    control={<Radio />}
                    label={<FormattedMessage id="otherLbl" />}
                  />
                </RadioGroup>
              </div>
              <div className="col">
                <TextField
                  name="comment"
                  required
                  onChange={handleInputChangeInner}
                  label={<FormattedMessage id="commentaryLbl" />}
                  color="primary"
                  className="form-control"
                />
                <TextField
                  required
                  name="referenced_number"
                  onChange={handleInputChangeInner}
                  label={<FormattedMessage id="referenceNumberLbl" />}
                  color="primary"
                  className="form-control"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleClose}
              className="btn btn-outline-primary m-2"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary m-2">
              EFECTUAR MOVIMIENTO{' '}
            </button>
          </DialogActions>
        </form>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnack} severity="success">
            <FormattedMessage id="updateInventoryAlerLbl" />
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
}
