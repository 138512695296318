import React, { useState } from "react";
import "./ColorPicker.scss"
// import ColorPicker from 'material-ui-color-picker';
import { ColorPicker } from "material-ui-color";
import { FormattedMessage } from "react-intl";

export default function CustomColorPicker(props) {

    const color = props.color

    const label = props.label

    const keyName = props.keyName

    // const [color, setColor] = useState("#000")

    const handleColorChange = (color)=>{
      let hexColor;
      if(typeof color === "string" && color.length >= 7){
        hexColor = color.substring(0,7)
        props.handleColorChange(hexColor, keyName)
      } else {
        hexColor = `#${color.hex}`
        props.handleColorChange(hexColor, keyName)
      }
        
    }

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------

  return (
    <>
      <label className="form-label color-label">
        <FormattedMessage id={label} />
      </label>
      <ColorPicker
        defaultValue={color}
        value={color}
        onChange={handleColorChange}
        className="color-picker"
        disablePlainColor 
      />
    </>
  );
}




{/* <div
        className="mt-1"
        style={{
          width: "25px",
          height: "25px",
          border: `1px solid ${color}`,
          backgroundColor: `${color}`,
          display: "inline-block",
        }}
      ></div>
      <ColorPicker
        name="color"
        defaultValue={color}
        style={{ width: "90%" }}
        value={color}
        onChange={handleColorChange}
      /> */}