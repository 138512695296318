import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/Table/Table";
import EditPrimaryIcon from "../../../assets/icons/edit-24px.svg";
import SearchBar from "../../../components/searchBar/searchBar";
import { getIngredients } from "../../../services/utilsIngredient/utilsIndredient";
import { Link } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';



const IngredientListPage = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  const [searchQuery, setSearchQuery] = useState("");

  const [ingredients, setIngredients] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [pagination, setPagination] = useState({});

  const [loading, setLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);

  const ingredientTableHeaders = [
    "nameHeader",
    "categoryHeader",
    "franchiseTypeHeader",
    "unitHeader",
    "actionsHeader",
  ];

  const createIngredientData = (name, category, franchiseType, unit, id) => {
    return {
      name,
      category,
      franchiseType,
      unit,
      actions: (
        <Link to={`/ingredientes/${name}/edit`} onClick={()=>goIngredient(id)}><img src={EditPrimaryIcon} alt="edit" onClick={() => console.log(id)} /></Link>
      ),
    };
  };

  const goIngredient = (id) => {
    localStorage.setItem("fk_ingredient", id);
  };

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async (page, rowsPerPage, searchQuery=null) => {
    setTableLoading(true)
    console.log(searchQuery)
    getIngredients(page, rowsPerPage, searchQuery).then((data) => {
      console.log("Fetch data done")
      let ingredientsData = data.ingredients.map((ingredient) =>
        createIngredientData(
          ingredient.name,
          ingredient.product_category.name,
          ingredient.product_category.franchise_type.name,
          ingredient.unit,
          ingredient.id
        )
      );
      setIngredients(ingredientsData);
      console.log(ingredients);

      let paginationObject = data.pagination;
      paginationObject.rowsPerPage = rowsPerPage;
      paginationObject.page = page;
      setPagination(paginationObject);
      setLoading(false)
      setTableLoading(false)
    });
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------
  const handleChangePage = async (newPage) => {
    await fetchData(newPage, rowsPerPage).then(() => {
      setPage(newPage);
    });
  };

  const handleChangeRowsPerPage = async (rowsPerPage) => {
    await fetchData(0, rowsPerPage).then(() => {
      setPage(0);
      setRowsPerPage(rowsPerPage);
    });
  };

  const handleTextSearch = async (input) => {
    setSearchQuery(input)
    fetchData(0, rowsPerPage, input)
    setPage(0);
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && 
      <>
      <SearchBar
      input={searchQuery}
      onChange={handleTextSearch}
      nombre="Ingredientes"
    />
    {tableLoading && <LinearProgress />}
    <CustomTable
      headers={ingredientTableHeaders}
      rows={ingredients}
      pagination={pagination}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
      
    </>
      }
    </>
  );
};

export default IngredientListPage;
