import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import "../../../index.scss";
import "./OperatorPage.scss";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getOperators } from "../../../services/utilsOperator/utilsOperator";

import SearchBar from "../../../components/searchBar/searchBar";
import OperatorCard from "../../../components/supervisor/OperatorCard/OperatorCard";

const OperatorPage = () => {
  const [inputOperator, setInputOperator] = useState("");
  const [operatorsListDefault, setOperatorsListDefault] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    getOperators().then((data) => {
      //setFranchisesList(data["franchises"]);
      setOperatorsList(data["operators"]);
      setOperatorsListDefault(data["operators"]);
      setLoading(false);
    });
  };

  const updateInputFranchise = async (input) => {
    const filtered = operatorsListDefault.filter((operator) => {
      return (
        operator["user"].first_name
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        operator["user"].last_name.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInputOperator(input);
    setOperatorsList(filtered);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id="operatorTitle" />
      </h1>
      <article>
        <Link to="trabajadores/create">
          <Fab size="small" color="secondary" className="fab" aria-label="edit">
            <AddIcon className="fab-icon" />
          </Fab>
        </Link>
        {!loading && (
          <>
            {operatorsListDefault && (
              <SearchBar
                input={inputOperator}
                onChange={updateInputFranchise}
                nombre="Trabajadores"
              />
            )}
            <div>
              <div className="franchises-continer">
                {operatorsList &&
                  operatorsList.map((operator, i) => (
                    <OperatorCard operator={operator} key={i}></OperatorCard>
                  ))}
              </div>
            </div>
          </>
        )}
        {loading && (
          <div className="d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </article>
    </main>
  );
};

export default OperatorPage;
