import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import {
  changePasswordWithTemporalAccessToken,
  verifyTemporalAccessToken,
} from "../../../services/usersManage/utilsUser";
import "./CredentialsPage.scss";
import { useLocation } from "react-router";
import KuruahLogo from "../../../assets/img/Kuruah-side-logo.svg";
import PlayStore from "../../../assets/img/en_badge_web_generic.png"
import AppStore from "../../../assets/img/222-2228547_app-store-download-on-app-store-transparent-gif.png"
import useUser from "../../../services/usersManage/useUser";

const CredentialsPage = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------
  const { logout } = useUser();
  const [passwords, setPasswords] = useState({
    password: "",
    repeatPassword: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [validLink, setValidLink] = useState(false);

  const [loading, setLoading] = useState(true);

  const [temporalAccessToken, setTemporalAccessToken] = useState("");

  const [successfull, setSuccessfull] = useState(false)

  const urlLocation = useLocation();

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------
  const fetchData = async () => {
    const urlPath = urlLocation.pathname.split("/");
    const token = urlPath[urlPath.length - 1]
    setTemporalAccessToken(token);
    console.log(urlPath);
    verifyTemporalAccessToken(token).then((data) => {
      console.log(data.valid_token);
      setValidLink(data.valid_token);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmitted(true)
    if (passwords.password === passwords.repeatPassword) {
      let formDataBody = new FormData();
      formDataBody.append("password", passwords.password);
      formDataBody.append("temporal_access_token", temporalAccessToken);
      changePasswordWithTemporalAccessToken(formDataBody).then((data)=>{
        console.log(data)
        if(data.error === undefined){
          setSuccessfull(true)
          // logout()
          setSubmitted(false)
        } else {
          alert("error")
        }
      });
    } else {
      alert("Passwords do not match");
    }
  };

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleInputChangeInner = (event) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className="credentials-article">
      <img src={KuruahLogo} className="kuruah-logo" alt="logo-kuruah" />
      <div
        className={`credentials-body ${!validLink ? "invalid-link-body" : ""}`}
      >
        {!loading && validLink && (
          <>
            <div className="credentials-title">
            {!successfull && <FormattedMessage id="credentialsTitle" />}
            {successfull && <FormattedMessage id="welcomeTitle" />}
            </div>
            {!successfull && (
              <form method="post">
                <div className="row justify-content-center">
                  <div className="col-9">
                    <div className="mb-4">
                      <TextField
                        required
                        type="password"
                        className="form-control"
                        id="standard-basic"
                        label={<FormattedMessage id="passwordLbl" />}
                        value={passwords.password}
                        name="password"
                        onChange={handleInputChangeInner}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-9">
                    <div>
                      <TextField
                        required
                        type="password"
                        className="form-control"
                        id="standard-basic"
                        label={<FormattedMessage id="repeatPasswordLbl" />}
                        value={passwords.repeatPassword}
                        name="repeatPassword"
                        onChange={handleInputChangeInner}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary m-2 credentials-button"
                    type="submit"
                    disabled={submitted}
                    onClick={handleSubmit}
                  >
                    {!submitted && <FormattedMessage id="acceptLbl" />}
                    {submitted && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div
                          className="spinner-border spinner-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            )}
            {successfull && (
              <>
              <div className="welcome-description">
              Ahora podrás disfrutar de los beneficios de la plataforma Kurúah, desde cualquier lugar. Ingresa a nuestra plataforma <a onClick={logout} href="http://localhost:3000">web</a>, o descarga las aplicación desde Play Store o AppStore.
              </div>
                <div className="store-buttons">
                {!(navigator.userAgent.indexOf("like Mac") !== -1)&&<a href="https://play.google.com/store/apps/details?id=co.foodpanel.admin" rel="noreferrer" target="_blank"><img src={PlayStore} className="store-image" alt="play-store-link" /></a>}
                {!(navigator.userAgent.indexOf("Android") !== -1)&&<a href="https://apps.apple.com/co/app/kur%C3%BAah/id1525943253?l=en" rel="noreferrer" target="_blank"><img src={AppStore} className="store-image" alt="app-store-link" /></a>}
                </div>


            </>)}
          </>
        )}
        {!successfull && !loading && !validLink && (
          <div className="credentials-invalid-description">
            <FormattedMessage id="invalidLinkDescription" />
          </div>
        )}
        {loading && (
          <div className="credentials-loading-spinner d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default CredentialsPage;
